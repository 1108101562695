import { RouterProvider } from 'react-router-dom';
import { FC } from 'react';

import { AuthLoader } from './AuthLoader';
import router from './router/router';

export const App: FC = () => {
  return (
    <AuthLoader>
      <RouterProvider router={router} />
    </AuthLoader>
  );
};
