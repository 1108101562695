import { Img } from '@app/components';
import { tMap } from '@app/i18n';
import { CardLinkProps, TServiceCard } from '@app/models';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import './service-card.scss';

type ServiceCardProps = CardLinkProps<TServiceCard> & {
  disableLink?: boolean;
};

export const ServiceCard = ({
  image,
  link,
  name,
  className,
  pictureClassName,
  disableLink,
}: ServiceCardProps): JSX.Element => {
  const imgNode = (
    <Img
      src={image?.url}
      className={cx(pictureClassName, 'service-card__image', 'service-card--hover')}
      alt={tMap(name)}
    />
  );
  return (
    <figure className={cx(className, 'service-card')}>
      {disableLink ? (
        imgNode
      ) : (
        <Link className="service-card__link" to={link}>
          {imgNode}
        </Link>
      )}
      <figcaption className="service-card__caption">{tMap(name)}</figcaption>
    </figure>
  );
};
