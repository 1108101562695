import { useAnimatedItems, useImagesLoaded, useMedia, useNotEnoughItems } from '@app/hooks';
import { CardLink } from '@app/models';
import colors from '@app/styles/colors.scss';
import cx from 'classnames';
import times from 'lodash/times';
import { useMemo, useRef } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { DummyCard } from '../dummy-card';
import { Img } from '../img/img';
import { CardGalleryProps, ImgLayoutProps } from './layout.props';
import { resolveCard } from './layout.utils';

import './slider.layout.scss';

export enum SliderLayoutType {
  /**
   * default layout type
   */
  SQUARES,

  /**
   * each 2n-th item is smaller, controlled by stripedClassName
   */
  STRIPED_SQUARES,

  /**
   * equal height, width is set based on intrinsic aspect ratio
   */
  FIXED_HEIGHT,

  /**
   * No default sizes
   */
  CUSTOM,
}

type CardSliderLayoutProps<T extends CardLink> = CardGalleryProps<T> & {
  layoutType?: SliderLayoutType;
  /**
   * works only with SliderLayoutType.STRIPED_SQUARES
   */
  stripedClassName?: string;
  spaceBetween?: number;
};

export const CardSliderLayout = <T extends CardLink>({
  cards,
  Card,
  CardFn,
  className,
  cardClassName: _cardClassName,
  stripedClassName,
  layoutType = SliderLayoutType.SQUARES,
  spaceBetween: _spaceBetween,
}: CardSliderLayoutProps<T>): JSX.Element => {
  const { isMobile } = useMedia();
  const sliderRef = useRef(null);
  const cardClassName = _cardClassName || 'slider-layout__card';
  const spaceBetween = _spaceBetween ?? (isMobile ? 24 : 30);
  const notEnoughCards = useNotEnoughItems(sliderRef, `.${cardClassName}:not(.dummy-card)`);

  const animatedCards = useAnimatedItems(cards, {
    itemSelector: '.slider-layout__slide',
    containerSelector: '.slider-layout__swiper',
    scope: sliderRef,
    deps: [notEnoughCards],
  });

  return (
    <div
      className={cx(className, 'slider-layout', {
        'slider-layout--squares':
          layoutType === SliderLayoutType.SQUARES || layoutType === SliderLayoutType.STRIPED_SQUARES,
        'slider-layout--striped': layoutType === SliderLayoutType.STRIPED_SQUARES,
        'slider-layout--fixed-height': layoutType === SliderLayoutType.FIXED_HEIGHT,
      })}
      ref={sliderRef}
    >
      <Swiper className="slider-layout__swiper" slidesPerView="auto" grabCursor spaceBetween={spaceBetween}>
        {animatedCards?.map((card, idx) => {
          const CardComponent = resolveCard(card, Card, CardFn);
          if (!CardComponent) {
            return null;
          }
          return (
            <SwiperSlide className="slider-layout__slide" key={card.id}>
              <CardComponent
                className={cx(cardClassName, {
                  [cx(stripedClassName || 'slider-layout__card--stripe')]:
                    layoutType === SliderLayoutType.STRIPED_SQUARES && idx % 2 === 0,
                })}
                pictureClassName="slider-layout__card-picture"
                {...card}
              />
            </SwiperSlide>
          );
        })}
        {times(notEnoughCards, (idx) => (
          <SwiperSlide className="slider-layout__slide" key={`dummy-${idx}`}>
            <DummyCard
              backgroundColor={colors.faintBgColor}
              className={cardClassName}
              pictureClassName="slider-layout__card-picture"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

type ImgSliderLayoutProps = ImgLayoutProps & {
  layoutType?: SliderLayoutType;
  /**
   * works only with SliderLayoutType.STRIPED_SQUARES
   */
  stripedClassName?: string;
};

export const ImgSliderLayout = ({
  images,
  layoutType = SliderLayoutType.SQUARES,
  stripedClassName,
  className,
  imgClassName,
}: ImgSliderLayoutProps): JSX.Element => {
  const { isMobile } = useMedia();
  const swiperRef = useRef<SwiperRef>(null);
  const imagesLoaded = useImagesLoaded(swiperRef.current as unknown as Element, [images]);

  const slides = useMemo(() => {
    /**
     * before images are loaded we want them to fade-out,
     * when loaded the slider is rerendered and we don't want images to fade-out again
     */
    const ImgTag = imagesLoaded ? 'img' : Img;
    return images.map((image, idx) => (
      <SwiperSlide className="slider-layout__slide" key={image.url}>
        <ImgTag
          className={cx(imgClassName || 'slider-layout__card', 'slider-layout__card-picture', {
            [cx(stripedClassName || 'slider-layout__card--stripe')]:
              layoutType === SliderLayoutType.STRIPED_SQUARES && idx % 2 === 0,
          })}
          src={image.url}
          alt=""
        />
      </SwiperSlide>
    ));
  }, [images, imgClassName, layoutType, stripedClassName, imagesLoaded]);

  return (
    <div
      className={cx(className, 'slider-layout', {
        'slider-layout--squares':
          layoutType === SliderLayoutType.SQUARES || layoutType === SliderLayoutType.STRIPED_SQUARES,
        'slider-layout--fixed-height': layoutType === SliderLayoutType.FIXED_HEIGHT,
      })}
    >
      <Swiper
        key={Number(imagesLoaded)}
        ref={swiperRef}
        className="slider-layout__swiper"
        slidesPerView="auto"
        grabCursor
        spaceBetween={isMobile ? 24 : 30}
      >
        {slides}
      </Swiper>
    </div>
  );
};
