import { SiteMap } from '@app/constants';
import { FeedbackForm } from '@app/features/feedback';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const FeedbackTab: FC = () => {
  const [formVisible, setFormVisible] = useState(true);
  const navigate = useNavigate();

  const onClose = useCallback(() => {
    setFormVisible(false);
    navigate(SiteMap.ACCOUNT);
  }, [navigate]);

  return (
    <main className="feedback-tab">
      <FeedbackForm open={formVisible} onClose={onClose} type="contact" />
    </main>
  );
};

export default FeedbackTab;
