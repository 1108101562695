import { LocalizedMap, Image } from '@app/models';

export enum LoyalityCardLevel {
  CUST0 = 'Cust0%',
  CUST5 = 'Cust5%',
  CUST10 = 'Cust10%',
  CUST15 = 'Cust15%',

  STAFF5 = 'Staff5%',
  STAFF10 = 'Staff10%',
  STAFF20 = 'Staff20%',
  STAFF30 = 'Staff30%',
  STAFF_VIP = 'Staff_VIP',

  CUST20 = 'Cust20%',
  CUST30 = 'Cust30%',
}

export enum MarketingAgreement {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  BOTH = 'BOTH',
}

export enum CustomerCaste {
  MERE_MORTAL,
  STAFF,
  GOD,
}

export type TLoyalityCard = {
  level: LoyalityCardLevel;
  discount: number;
  minSum: number | null;
};

export type Customer = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  email?: string;
  birthday?: Date;
  cardId: string;
  renderedCardId: string;
  status: 'active' | 'disabled';
  customerId: string;
  cardLevel: LoyalityCardLevel;
  spentSum: number;
  promoEndsAt?: Date;
  marketingAgreement?: MarketingAgreement;
  activePromo?: string;
  cards: TLoyalityCard[];
};

export type LoyalityBrand = {
  id: string;
  name: LocalizedMap;
  logo: Image;
  floor: number;
  card: TLoyalityCard;
};
