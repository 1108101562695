import { CardProps, ColorfulCard, TDummyCard } from '@app/models';
import colors from '@app/styles/colors.scss';
import cx from 'classnames';

export type DummyCardProps<T extends ColorfulCard = ColorfulCard> = TDummyCard<T> & CardProps;

export const DummyCard = ({ backgroundColor, className, pictureClassName }: DummyCardProps): JSX.Element => {
  return (
    <div className={cx(className, 'dummy-card')}>
      <div
        className={cx(pictureClassName, `${className}__bg`)}
        style={{
          backgroundColor: backgroundColor || colors.mainBgColor,
        }}
      />
    </div>
  );
};
