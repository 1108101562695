import { Button } from '@app/components';
import { FLOORS } from '@app/constants';
import { useAnimatedItems, useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import groupBy from 'lodash/groupBy';
import { FC, useEffect, useMemo, useState } from 'react';
import { LoyalityBrand } from '../../account.model';
import { useCustomerQuery } from '../../customer-query.hook';
import { LoyalityProgressBar } from '../loyality/loyality-progress-bar';
import { BrandsSearch } from './brands-search';
import { LoyalityBrandCard } from './loyality-brand-card';
import { useLoyalityBrandCardsLazyQuery } from './loyality-brand-cards-lazy-query.hook';

import './brands.tab.scss';

enum SortType {
  ALPHABET,
  FLOOR,
}

const searchForBrands = (brands: LoyalityBrand[], prompt: string): LoyalityBrand[] => {
  if (!prompt) return [...brands];
  return brands.filter((brand) => {
    return Object.values(brand.name).some((brandName) => brandName.toLowerCase().includes(prompt));
  });
};

export const BrandsTab: FC = () => {
  const { t, tRoot, tMap } = useEnhancedTranslation({ keyPrefix: 'account.brands' });
  const { data: customer } = useCustomerQuery();
  const [sortType, setSortType] = useState<SortType>(SortType.ALPHABET);
  const [loadBrands, { data: brands }] = useLoyalityBrandCardsLazyQuery();
  const [searchPrompt, setSearchPrompt] = useState('');

  const sortedBrands = useMemo<LoyalityBrand[]>(
    () =>
      searchForBrands(brands, searchPrompt).sort((brand1, brand2) => (tMap(brand1.name) < tMap(brand2.name) ? -1 : 1)),
    [brands, searchPrompt, tMap],
  );

  const sortedAnimatedBrands = useAnimatedItems(sortedBrands, {
    itemSelector: '.loyality-brand',
    containerSelector: '.brands-tab__brands',
    scope: '.brands-tab',
  });

  const brandsByFloor = useMemo<Record<number, LoyalityBrand[]>>(
    () => groupBy(sortedAnimatedBrands, 'floor'),
    [sortedAnimatedBrands],
  );

  useEffect(() => {
    if (!customer) return;
    loadBrands({ promoLevelDto: { level: customer.cardLevel } });
  }, [customer, loadBrands]);

  if (!customer) {
    return null;
  }

  return (
    <main className="brands-tab">
      <h1 className="brands-tab__title">{t('title')}</h1>

      <LoyalityProgressBar
        className="brands-tab__progress-bar"
        cards={customer.cards}
        spentSum={customer.spentSum}
        currentCardLevel={customer.cardLevel}
      />

      <div className="brands-tab__controls">
        <BrandsSearch className="brands-tab__search" onChange={setSearchPrompt} />

        <div className="brands-tab__sort-type">
          <Button
            className={cx('brands-tab__sort-btn', {
              'brands-tab__sort-btn--active': sortType === SortType.ALPHABET,
            })}
            onClick={() => setSortType(SortType.ALPHABET)}
          >
            {t('alphabetSorting')}
          </Button>
          <Button
            className={cx('brands-tab__sort-btn', {
              'brands-tab__sort-btn--active': sortType === SortType.FLOOR,
            })}
            onClick={() => setSortType(SortType.FLOOR)}
          >
            {t('floorSorting')}
          </Button>
        </div>
      </div>

      {sortType === SortType.ALPHABET && (
        <div className="brands-tab__brands-group">
          <div className="brands-tab__brands">
            {sortedAnimatedBrands?.map((brand) => (
              <LoyalityBrandCard key={brand.id} {...brand} />
            ))}
          </div>
        </div>
      )}

      {sortType === SortType.FLOOR &&
        FLOORS.map((floor) =>
          brandsByFloor[floor] ? (
            <div className="brands-tab__brands-group" key={floor}>
              <h2 className="brands-tab__floor">{tRoot('floors.floorNum', { floor })}</h2>
              <div className="brands-tab__brands">
                {brandsByFloor[floor].map((brand) => (
                  <LoyalityBrandCard key={brand.id} {...brand} />
                ))}
              </div>
            </div>
          ) : null,
        )}
    </main>
  );
};

export default BrandsTab;
