import { SiteMap } from '@app/constants';
import { TBrandCard } from '@app/models';
import { compareSortable } from '@app/utils';
import { refineCornerId, refineFloor } from './mappers.utils';
import { convertImage, toTag } from './media.mappers';
import { BrandResponseDto, EventMain, MappedEventInformationDto } from '../models';

export const toBrandCard = ({
  shortId,
  name,
  seoName,
  images,
  floor,
  sortOrder,
  cornerId,
  tags,
}: BrandResponseDto | EventMain | MappedEventInformationDto): TBrandCard => {
  const image = convertImage(images?.find((img) => img.type === 'catalog'));
  const cover = convertImage(
    images?.find((img) => ['cover', 'coverBrand', 'coverMainVertical', 'coverMainHorizontal'].includes(img.type)),
  );
  const logo = convertImage(images?.find((img) => img.type === 'logo'));
  return {
    id: shortId,
    name,
    link: `${SiteMap.BRANDS}/${seoName}-${shortId}`,
    image,
    cover,
    type: 'brand',
    floor: refineFloor(floor),
    logo,
    tags: tags?.map(toTag)?.sort(compareSortable) ?? [],
    sortOrder,
    cornerId: refineCornerId(cornerId),
  };
};

export const toBrandTile = (brandDto: BrandResponseDto | EventMain | MappedEventInformationDto): TBrandCard => {
  const image = convertImage(
    brandDto.images?.find((img) => ['coverMainHorizontal', 'coverMainVertical'].includes(img.type)),
  );
  return {
    ...toBrandCard(brandDto),
    image,
  };
};
