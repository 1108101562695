import servicePopoverBg from '@app/assets/images/loyality-service-popover-bg.webp';
import { LinkButton, Popover } from '@app/components';
import { ServiceCard } from '@app/features/services';
import { useEnhancedTranslation, useMedia } from '@app/hooks';
import { TServiceCard } from '@app/models';
import cx from 'classnames';
import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLoyalityServices } from './loyality-services.hook';

import './loyality-services.scss';

type LoyalityServicesProps = {
  discountLevel: string;
  className?: string;
};

export const LoyalityServices: FC<LoyalityServicesProps> = ({ discountLevel, className }) => {
  const { isMobile } = useMedia();
  const { t, tMap } = useEnhancedTranslation({ keyPrefix: 'account.loyality.services' });
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { data: services } = useLoyalityServices();
  const [clickedService, setClickedService] = useState<TServiceCard>();

  return (
    <section className={cx(className, 'loyality-services')}>
      <h2 className="loyality-services__title">{t('title')}</h2>
      <Swiper className="loyality-services__swiper" slidesPerView="auto" grabCursor spaceBetween={isMobile ? 16 : 28}>
        {services.map((service) => (
          <SwiperSlide
            className={cx('loyality-services__card', {
              'loyality-services__card--unavailable': !service.discountLevels?.includes(discountLevel),
            })}
            onClick={() => {
              setClickedService(service);
              setPopoverVisible(true);
            }}
            key={service.id}
            tag="figure"
          >
            <ServiceCard pictureClassName="loyality-services__image" disableLink {...service} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Popover
        open={popoverVisible}
        className="loyality-services__popover"
        style={{ backgroundImage: `url(${servicePopoverBg})` }}
        onClose={() => setPopoverVisible(false)}
      >
        <h3 className="loyality-services__popover-title">{tMap(clickedService?.name)}</h3>
        <p className="loyality-services__popover-description">{tMap(clickedService?.loyalityDescription)}</p>
        {clickedService?.link && <LinkButton to={clickedService.link}>{t('goToService')}</LinkButton>}
      </Popover>
    </section>
  );
};
