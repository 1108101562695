import { FLOORS } from '@app/constants';

export const refineCornerId = (cornerId: string | undefined): string | undefined => {
  return cornerId?.trim().split('_').at(-1);
};

export const refineFloor = (floor: number | undefined): number | undefined => {
  if (floor && FLOORS.includes(floor)) return floor;
  return undefined;
};

export const calcSortOrderByDate = (publishedDate: Date | undefined, highPriority = false): number => {
  let sortOrder = 0;
  if (publishedDate) {
    sortOrder += publishedDate.getTime() / 30_000_000_000;
  }
  if (highPriority) {
    sortOrder += 100;
  }
  return sortOrder;
};
