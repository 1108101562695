import cx from 'classnames';
import clamp from 'lodash/clamp';
import isNumber from 'lodash/isNumber';
import { FC, useMemo } from 'react';
import { LoyalityCardLevel, TLoyalityCard } from '../../account.model';

import './loyality-progress-bar.scss';

type LoyalityProgressBarProps = {
  className?: string;
  cards: TLoyalityCard[];
  currentCardLevel: LoyalityCardLevel;
  spentSum: number;
};

export const LoyalityProgressBar: FC<LoyalityProgressBarProps> = ({ className, cards, spentSum, currentCardLevel }) => {
  const progress = useMemo(() => {
    if (cards.length !== 3 || cards.some((card) => !isNumber(card.minSum))) return 0;
    const currentCardIdx = cards.findIndex((card) => card.level === currentCardLevel);
    if (currentCardIdx === 2) return 2;
    const progressToNextCard =
      (spentSum - cards[currentCardIdx].minSum!) / (cards[currentCardIdx + 1].minSum! - cards[currentCardIdx].minSum!);
    return Math.max(currentCardIdx + progressToNextCard, 0);
  }, [cards, currentCardLevel, spentSum]);

  if (cards.length !== 3) return null;

  return (
    <div className={cx(className, 'loyality-progress')}>
      <span className={cx('loyality-progress__point', { 'loyality-progress__point--active': progress >= 0 })}>
        {`${cards[0].discount}%`}
      </span>
      <div className="loyality-progress__skeleton">
        <div className="loyality-progress__progress" style={{ right: `${100 - clamp(progress, 0, 1) * 100}%` }} />
      </div>
      <span className={cx('loyality-progress__point', { 'loyality-progress__point--active': progress >= 1 })}>
        {`${cards[1].discount}%`}
      </span>
      <div className="loyality-progress__skeleton">
        <div
          className="loyality-progress__progress"
          style={{ right: `${100 - (clamp(progress, 1, 2) - 1) * 100}%`, transitionDuration: '0.6s' }}
        />
      </div>
      <span className={cx('loyality-progress__point', { 'loyality-progress__point--active': progress === 2 })}>
        {`${cards[2].discount}%`}
      </span>
    </div>
  );
};
