import { SmartCaptcha } from '@yandex/smart-captcha';
import { useEnhancedTranslation } from '@app/hooks';
import { Button } from '@app/components/index';
import { Form, Formik, FormikProps } from 'formik';
import { FC, useState, useMemo, useRef, useEffect } from 'react';
import cx from 'classnames';
import { AuthCodeField, CODE_SCHEMA } from './auth-code-field';
import { AuthCodeStatus } from './auth.model';
import './auth.scss';
import { useAuthCodeStatus } from './useAuthCodeStatus';
import './resend-code-label.scss';

const captchaClientKey = process.env.REACT_APP_YANDEX_SMART_CAPTCHA_CLIENT_KEY || '';
export const CODE_REGEXP = /^[0-9]{4}$/;

type PhoneConfirmationFormProps = {
  onSubmit: (code: string, captcha: string) => void;
  onResend: (captcha: string) => Promise<void>;
  status: AuthCodeStatus;
  error: string;
};

type TLang = 'ru' | 'en';

export const PhoneConfirmationForm: FC<PhoneConfirmationFormProps> = ({ onSubmit, onResend, status, error }) => {
  const { t, i18n } = useEnhancedTranslation({ keyPrefix: 'auth' });
  const userLanguage = (i18n.language || 'ru') as TLang;
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const [resetCaptcha, setResetCaptcha] = useState(0);
  const [visibleErrorCaptchaLabel, setVisibleErrorCaptchaLabel] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getTime() + 60 * 1000));
  const { secondsLeft, startTimer } = useAuthCodeStatus({ attemptExpiresAt: startDate });

  const label = useMemo(() => {
    switch (status) {
      case AuthCodeStatus.PENDING:
        return t('codeSent');
      case AuthCodeStatus.INVALID:
        return t('wrongCode');
      case AuthCodeStatus.CODE_EXPIRED:
        return t('codeExpired');
      case AuthCodeStatus.LOGIN_ERROR:
        return t('loginError');
      case AuthCodeStatus.VALIDATING:
        return '';
      default:
        return t('loginError');
    }
  }, [status, error]);

  const handleReset = (): void => {
    setCaptchaToken('');
    setResetCaptcha((prev) => prev + 1);
  };

  const formikRef = useRef<FormikProps<{ code: string }>>(null);

  const handleResend = async (): Promise<void> => {
    formikRef.current?.setFieldValue('code', '', false);
    if (!captchaToken) {
      setVisibleErrorCaptchaLabel(true);
    } else {
      await onResend(captchaToken);
      const expiresAt = new Date(new Date().getTime() + 60 * 1000);
      setStartDate(expiresAt);
      startTimer();
      handleReset();
    }
  };

  const handleSubmit = (values: { code: string }): void => {
    onSubmit(values.code, captchaToken);
    handleReset();
  };

  const updateCaptchaToken = (token: string): void => {
    setVisibleErrorCaptchaLabel(false);
    setCaptchaToken(token);
  };

  useEffect(() => {
    if (status === AuthCodeStatus.INVALID && formikRef.current) {
      formikRef.current.setFieldValue('code', '', false);
    }
  }, [status]);

  return (
    <div className="auth__inner">
      <h2 className="auth__title">{t('confirm')}</h2>
      <Formik
        initialValues={{ code: '' }}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validate={() => {
          if (status === AuthCodeStatus.LOGIN_ERROR) {
            return { code: 'login_error' };
          }
          return {};
        }}
        validationSchema={CODE_SCHEMA}
      >
        {({ errors, values }) => (
          <Form className="auth__form">
            <AuthCodeField
              className="auth__label"
              error={errors.code}
              disabled={status === AuthCodeStatus.VALIDATING}
            />

            <div className="auth__captcha-container">
              <SmartCaptcha
                key={resetCaptcha}
                sitekey={captchaClientKey}
                onSuccess={updateCaptchaToken}
                onTokenExpired={handleReset}
                language={userLanguage}
              />
              {visibleErrorCaptchaLabel ? <p className="auth__error">{t('resendCaptchaError')}</p> : null}
            </div>

            <p className="resend-code">
              <span
                className={cx('resend-code__label', {
                  'resend-code__label--error':
                    status === AuthCodeStatus.INVALID ||
                    status === AuthCodeStatus.LOGIN_ERROR ||
                    status === AuthCodeStatus.CODE_EXPIRED,
                })}
              >
                {label}
              </span>
              <br />
              {status !== AuthCodeStatus.LOGIN_ERROR &&
                (secondsLeft === 0 ? (
                  <button type="button" className="resend-code__btn" onClick={handleResend}>
                    {t('resend')}
                  </button>
                ) : (
                  <span className="resend-code__label">{t('resendIn', { minutes: 0, seconds: secondsLeft })}</span>
                ))}
            </p>

            <Button
              disabled={!captchaToken || !CODE_REGEXP.test(values.code) || status === AuthCodeStatus.VALIDATING}
              className="auth__submit-btn"
              type="submit"
            >
              {t('confirmNumber')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
