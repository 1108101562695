import loyalityTermsEn from '@app/assets/docs/loyality-terms_en.md';
import loyalityTermsRu from '@app/assets/docs/loyality-terms_ru.md';
import { Markdown } from '@app/components';
import { LocalizedMap } from '@app/models';
import { FC } from 'react';

import './loyality-terms.tab.scss';

const LOYALITY_TERMS: LocalizedMap = {
  ru: loyalityTermsRu,
  en: loyalityTermsEn,
};

export const LoyalityTermsTab: FC = () => {
  return (
    <main className="loyality-terms-tab">
      <Markdown tag="section" src={LOYALITY_TERMS} />
    </main>
  );
};

export default LoyalityTermsTab;
