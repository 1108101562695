import { connectEmptyApi as api } from '../connect-empty-api';
import {
  FeedbacksControllerCreateApiArg,
  FeedbacksControllerCreateApiResponse,
  FeedbacksControllerFindOneApiArg,
  FeedbacksControllerFindOneApiResponse,
  FeedbacksControllerUnRegisterApiArg,
  FeedbacksControllerUnRegisterApiResponse,
} from './types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    feedbacksControllerFindOne: build.mutation<FeedbacksControllerFindOneApiResponse, FeedbacksControllerFindOneApiArg>(
      {
        query: (queryArg) => ({ url: `/api/v1/feedbacks/${queryArg.phone}/${queryArg.shortId}`, method: 'GET' }),
      },
    ),
    feedbacksControllerUnRegister: build.mutation<
      FeedbacksControllerUnRegisterApiResponse,
      FeedbacksControllerUnRegisterApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/feedbacks/${queryArg.phone}/${queryArg.shortId}`, method: 'DELETE' }),
    }),
    feedbacksControllerCreate: build.mutation<FeedbacksControllerCreateApiResponse, FeedbacksControllerCreateApiArg>({
      query: (queryArg) => ({ url: '/api/v1/feedbacks', method: 'POST', body: queryArg.feedbackDto }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };

export const {
  useFeedbacksControllerFindOneMutation,
  useFeedbacksControllerUnRegisterMutation,
  useFeedbacksControllerCreateMutation,
} = injectedRtkApi;
