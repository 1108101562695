/* eslint-disable react/destructuring-assignment */
import { useFeedbacksControllerUnRegisterMutation } from '@app/api/tagged/feedback-api';
import { Button, ButtonStyle } from '@app/components';
import { getPhone } from '@app/features/auth';
import { EventCard } from '@app/features/events';
import { useEnhancedTranslation } from '@app/hooks';
import { useCallback } from 'react';

import './my-event-card.scss';

export const MyEventCard: typeof EventCard = (props) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.myEvents' });

  const [unregister] = useFeedbacksControllerUnRegisterMutation();

  const handleUnregister = useCallback(() => {
    unregister({ phone: getPhone(), shortId: props.id });
  }, [props.id, unregister]);

  return (
    <div className="my-event-card">
      <EventCard {...props} />
      <Button
        onClick={handleUnregister}
        size="small"
        className="my-event-card__unregister-btn"
        styling={ButtonStyle.ARROW}
      >
        {t('unregister')}
      </Button>
    </div>
  );
};
