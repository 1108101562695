import { ReactComponent as ChevronIcon } from '@app/assets/icons/chevron.svg';
import cx from 'classnames';
import { openEl, closeEl } from '@app/utils';
import { Key, ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { InteractiveDiv } from '../interactive-div';
import '@app/index.scss';

import './accordion.scss';

type AccordionProps<T> = {
  items: T[];
  selectId: (item: T) => string;
  renderSummary: (item: T) => ReactNode;
  renderContent: (item: T) => ReactNode;
  className?: string;
  itemClassName?: string;
  contentClassName?: string;
  active?: T;
  onActive?: (item: T | null) => void;
  onHover?: (item: T | null) => void;
};

export const Accordion = <T,>({
  className,
  itemClassName,
  contentClassName,
  items,
  selectId,
  renderSummary,
  renderContent,
  active,
  onActive,
  onHover,
}: AccordionProps<T>): JSX.Element => {
  const contentsRef = useRef<Record<string | number, HTMLDivElement>>({});

  const [activeId, setActiveId] = useState<Key | null>(null);

  const onHeaderClick = useCallback(
    (clickedAccordion: T) => {
      const newActiveId = selectId(clickedAccordion) !== activeId ? selectId(clickedAccordion) : null;
      setActiveId(newActiveId);
      onActive?.(newActiveId ? clickedAccordion : null);
      Object.entries(contentsRef.current).forEach(([id, contentEl]) => {
        if (id === newActiveId) {
          openEl(contentEl);
        } else {
          closeEl(contentEl);
        }
      });
    },
    [activeId, onActive, selectId],
  );

  useLayoutEffect(() => {
    active && onHeaderClick(active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <menu className={cx(className, 'accordion')}>
      {items.map((item) => {
        const id = selectId(item);
        return (
          <li key={id} className={cx(itemClassName, 'accordion-item')}>
            <InteractiveDiv
              className="accordion-header"
              onClick={() => onHeaderClick(item)}
              onMouseEnter={() => onHover?.(item)}
              onMouseLeave={() => onHover?.(null)}
            >
              {renderSummary(item)}
              <ChevronIcon
                className={cx('accordion-header__icon', {
                  'accordion-header__icon--flipped': activeId === id,
                })}
              />
            </InteractiveDiv>
            <div
              ref={(el) => {
                el && (contentsRef.current[id] = el);
              }}
              className={cx(contentClassName, 'accordion-content')}
            >
              {renderContent(item)}
            </div>
          </li>
        );
      })}
    </menu>
  );
};
