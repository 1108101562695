import { toEventCard } from '@app/api/mappers';
import { usePagesControllerEventsByPhoneQuery } from '@app/api/tagged/pages-api';
import { useAppSelector } from '@app/hooks/redux';
import { TEventCard } from '@app/models';
import { useMemo } from 'react';

export const useMyEventsQuery = (): ReturnType<typeof usePagesControllerEventsByPhoneQuery> & {
  data: TEventCard[];
} => {
  const { phone } = useAppSelector((state) => state.authReducer.access);
  const resp = usePagesControllerEventsByPhoneQuery({ phone: phone as string }, { skip: !phone });
  const data = useMemo<TEventCard[]>(() => {
    return Array.isArray(resp.data) ? resp.data.map(toEventCard) : [];
  }, [resp.data]);
  return { ...resp, data };
};
