import defaultLogo from '@app/assets/icons/default-logo.svg';
import {
  Customer,
  LoyalityBrand,
  LoyalityCardLevel,
  MarketingAgreement,
  TLoyalityCard,
} from '@app/features/account/account.model';
import { AvailableDiscountLevel, CustomerResponseDto } from '../external-api';
// import { BrandsDiscountDto, DiscountLevelDto } from '../pages-api';
import { convertImage } from './media.mappers';
import { BrandsDiscountDto, DiscountLevelDto } from '../models';

const toLoyalityCard = ({ level, discount, minSum }: AvailableDiscountLevel | DiscountLevelDto): TLoyalityCard => ({
  discount: +discount,
  level: level as LoyalityCardLevel,
  minSum,
});

const compareLoyalityCards = (card1: TLoyalityCard, card2: TLoyalityCard): number => {
  return card1.discount < card2.discount ? -1 : 1;
};

export const toCustomer = (dto: CustomerResponseDto): Customer => {
  return {
    ...dto,
    id: dto.customerId,
    firstName: dto.firstName ?? dto.firstname,
    lastName: dto.lastName ?? dto.lastname,
    middleName: dto.middleName ?? '',
    // eslint-disable-next-line no-nested-ternary
    birthday: dto.birthday ? new Date(dto.birthday) : dto.dob ? new Date(dto.dob) : undefined,
    renderedCardId: `;${dto.cardId}?`,
    cards: dto.availableDiscountLevels?.map(toLoyalityCard).sort(compareLoyalityCards) ?? [],
    marketingAgreement: dto.marketingAgreement as MarketingAgreement,
    cardLevel: dto.discountLevel as LoyalityCardLevel,
    promoEndsAt: dto.dataDiscountUntill ? new Date(dto.dataDiscountUntill) : undefined,
    spentSum: dto.spendAmount,
    status: dto.status ?? 'active',
    activePromo: dto.activePromo || undefined,
  };
};

export const toLoyalityBrandCard = (
  { promoId, discountLevel: discountLevels, name, images, floor }: BrandsDiscountDto,
  cardLevel: LoyalityCardLevel,
): LoyalityBrand => {
  return {
    id: promoId,
    name,
    logo: convertImage(images.find((image) => image.type === 'logo')) ?? { url: defaultLogo },
    floor,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    card: toLoyalityCard(discountLevels.find((level) => level.level === cardLevel)!),
  };
};
