import { CustomerCaste, LoyalityCardLevel } from './account.model';

export const getCustomerCaste = (discountLevel: LoyalityCardLevel): CustomerCaste | null => {
  switch (discountLevel) {
    case LoyalityCardLevel.CUST0:
    case LoyalityCardLevel.CUST5:
    case LoyalityCardLevel.CUST10:
    case LoyalityCardLevel.CUST15: {
      return CustomerCaste.MERE_MORTAL;
    }
    case LoyalityCardLevel.STAFF5:
    case LoyalityCardLevel.STAFF10:
    case LoyalityCardLevel.STAFF20:
    case LoyalityCardLevel.STAFF30:
    case LoyalityCardLevel.STAFF_VIP: {
      return CustomerCaste.STAFF;
    }
    case LoyalityCardLevel.CUST20:
    case LoyalityCardLevel.CUST30: {
      return CustomerCaste.GOD;
    }
    default: {
      return null;
    }
  }
};
