/* eslint-disable max-len */
import { CardSliderLayout, LinkButton } from '@app/components';
import { SiteMap } from '@app/constants';
import { EventCard } from '@app/features/events';
import { useEnhancedTranslation, useMedia } from '@app/hooks';
import { isAfter, isWithinInterval } from 'date-fns';
import { FC, useMemo } from 'react';
import { MyEventCard } from './my-event-card';
import { useMyEventsQuery } from './my-events-query.hook';

import './my-events.tab.scss';

export const MyEventsTab: FC = () => {
  const { isMobile } = useMedia();
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.myEvents' });

  const { data: events } = useMyEventsQuery();

  const currentEvents = useMemo(() => events.filter((event) => isWithinInterval(Date.now(), event.period)), [events]);
  const upcomingEvents = useMemo(() => events.filter((event) => isAfter(event.period.start, Date.now())), [events]);
  const passedEvents = useMemo(() => events.filter((event) => isAfter(Date.now(), event.period.end)), [events]);

  return (
    <main className="my-events-tab">
      <h1 className="my-events-tab__title">{t('title')}</h1>

      {!!currentEvents.length && (
        <section className="my-events-tab__group">
          <CardSliderLayout
            className="my-events-tab__slider"
            cardClassName="my-events-tab__card"
            cards={currentEvents}
            Card={MyEventCard}
            spaceBetween={isMobile ? 16 : 30}
          />
        </section>
      )}

      {(!!upcomingEvents.length || !currentEvents.length) && (
        <section className="my-events-tab__group">
          <h2 className="my-events-tab__subtitle">{t('upcoming')}</h2>
          {!!upcomingEvents.length && (
            <CardSliderLayout
              className="my-events-tab__slider"
              cardClassName="my-events-tab__card"
              cards={upcomingEvents}
              Card={MyEventCard}
              spaceBetween={isMobile ? 16 : 30}
            />
          )}
          {!upcomingEvents.length && !currentEvents.length && (
            <>
              <p className="my-events-tab__description">{t('noEvents')}</p>
              <LinkButton className="my-events-tab__events-link" to={SiteMap.EVENTS}>
                {t('chooseEvents')}
              </LinkButton>
            </>
          )}
        </section>
      )}

      {!!passedEvents.length && (
        <section className="my-events-tab__group">
          <h2 className="my-events-tab__subtitle">{t('passed')}</h2>
          <CardSliderLayout
            className="my-events-tab__slider"
            cardClassName="my-events-tab__card my-events-tab__card--inactive"
            cards={passedEvents}
            Card={EventCard}
            spaceBetween={isMobile ? 16 : 30}
          />
        </section>
      )}
    </main>
  );
};

export default MyEventsTab;
