import { ReactComponent as PencilIcon } from '@app/assets/icons/pencil.svg';
import { SiteMap } from '@app/constants';
import { useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import { isValid, parse } from 'date-fns';
import { Field, FieldProps } from 'formik';
import { FC, InputHTMLAttributes, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import MaskedInput, { Mask, conformToMask } from 'react-text-mask';
import * as Yup from 'yup';

const PHONE_REGEXP = /\+7 *\(?[0-9]{3}\)? *[0-9]{3}(-| )*[0-9]{2}(-| )*[0-9]{2}/;
const PHONE_MASK: Mask = [
  '+',
  '7',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const PHONE_PLACEHOLDER = '+7 (___) ___ - __ - __';

export const DATE_FORMAT = 'dd.MM.yyyy';
const DATE_MASK: Mask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

const NAME_REGEXP = /^[^ ][А-Яа-я0-9- ']+[^ ]$/;

export enum CommonFormFieldName {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  PHONE = 'phone',
  EMAIL = 'email',
  BIRTHDAY = 'birthday',
  POLICY_AGREEMENT = 'policyAgreement',
}

export const FIRST_NAME_SCHEMA = Yup.object({
  [CommonFormFieldName.FIRST_NAME]: Yup.string().matches(NAME_REGEXP).required(),
});
export const LAST_NAME_SCHEMA = Yup.object({
  [CommonFormFieldName.LAST_NAME]: Yup.string().matches(NAME_REGEXP).required(),
});
// export const MIDDLE_NAME_SCHEMA = Yup.object({
//   [CommonFormFieldName.MIDDLE_NAME]: Yup.string().matches(NAME_REGEXP).required(),
// });
export const PHONE_SCHEMA = Yup.object({
  [CommonFormFieldName.PHONE]: Yup.string().matches(PHONE_REGEXP).required(),
});
export const EMAIL_SCHEMA = Yup.object({
  [CommonFormFieldName.EMAIL]: Yup.string().email().required(),
});
export const BIRTHDAY_SCHEMA = Yup.object({
  [CommonFormFieldName.BIRTHDAY]: Yup.string().test({
    test: (dateStr) => {
      const date = parse(dateStr ?? '', DATE_FORMAT, new Date());
      return isValid(date);
    },
  }),
});
export const POLICY_AGREEMENT_SCHEMA = Yup.object({
  [CommonFormFieldName.POLICY_AGREEMENT]: Yup.boolean().isTrue().required(),
});

export const COMMON_FORM_FIELDS_SCHEMA = Yup.object()
  .concat(FIRST_NAME_SCHEMA)
  .concat(LAST_NAME_SCHEMA)
  // .concat(MIDDLE_NAME_SCHEMA)
  .concat(PHONE_SCHEMA)
  .concat(EMAIL_SCHEMA);

export const COMMON_FORM_FIELDS_INITIAL_VALUES = Object.freeze({
  [CommonFormFieldName.FIRST_NAME]: '',
  [CommonFormFieldName.LAST_NAME]: '',
  [CommonFormFieldName.MIDDLE_NAME]: '',
  [CommonFormFieldName.PHONE]: '',
  [CommonFormFieldName.EMAIL]: '',
});

export type TzFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  readOnly?: boolean;
  error?: string;
  required?: boolean;
  editable?: boolean;
  onEdit?: () => void;
};

type EditableFieldControlsProps = {
  onEdit: () => void;
  readOnly?: boolean;
};

export const EditableFieldControls: FC<EditableFieldControlsProps> = ({ onEdit, readOnly }) => {
  return readOnly ? <PencilIcon className="field-edit-icon" onClick={onEdit} /> : null;
};

export const formatPhone = (rawPhone: string): string => {
  const cleanPhone = rawPhone.replace(/[^+\d]+/g, '');
  if (cleanPhone.length === 11 && cleanPhone.startsWith('8')) {
    return conformToMask(cleanPhone.replace(/^(8)/, '+7'), PHONE_MASK).conformedValue;
  }
  if (cleanPhone.length === 11 && cleanPhone.startsWith('7')) {
    return conformToMask(cleanPhone.replace(/^(7)/, '+7'), PHONE_MASK).conformedValue;
  }
  return conformToMask(rawPhone, PHONE_MASK).conformedValue;
};

export const PhoneField: FC<TzFieldProps> = ({ className, readOnly, error, required, editable, onEdit, ...rest }) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'common.form' });
  return (
    <label className={cx(className, 'field-label')} htmlFor="phone">
      {`${t('phone')}${required ? '*' : ''}`}
      <Field name="phone">
        {({ field }: FieldProps) => (
          <MaskedInput
            {...field}
            {...rest}
            required={required}
            readOnly={readOnly}
            onKeyDown={({ key }) => key === 'Enter' && onEdit?.()}
            className={cx('text-input', { error: !!error })}
            mask={PHONE_MASK}
            pipe={(_, config) => formatPhone(config.rawValue)}
            placeholder={PHONE_PLACEHOLDER}
            type="tel"
          />
        )}
      </Field>
      {editable && onEdit && <EditableFieldControls onEdit={onEdit} readOnly={readOnly} />}
    </label>
  );
};

export const BirthdayField: FC<TzFieldProps> = ({ className, readOnly, error, required, editable, onEdit }) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'common.form' });

  return (
    <label className={cx(className, 'field-label')} htmlFor={CommonFormFieldName.BIRTHDAY}>
      {`${t(CommonFormFieldName.BIRTHDAY)}${required ? '*' : ''}`}
      <Field name={CommonFormFieldName.BIRTHDAY}>
        {({ field }: FieldProps) => (
          <MaskedInput
            {...field}
            readOnly={readOnly}
            required={required}
            onKeyDown={({ key }) => key === 'Enter' && onEdit?.()}
            className={cx('text-input', { error: !!error })}
            mask={DATE_MASK}
            placeholder={t('datePlaceholder') || undefined}
            type="text"
          />
        )}
      </Field>
      {editable && onEdit && <EditableFieldControls onEdit={onEdit} readOnly={readOnly} />}
      {error && <div className="error-label">{error}</div>}
    </label>
  );
};

export const SimpleField: FC<TzFieldProps & { name: string; type?: string }> = ({
  name,
  type = 'text',
  className,
  readOnly,
  error,
  required,
  editable,
  onEdit,
  ...rest
}) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'common.form' });
  return (
    <label className={cx(className, 'field-label')} htmlFor={name}>
      {`${t(name)}${required ? '*' : ''}`}
      <Field
        {...rest}
        readOnly={readOnly}
        required={required}
        onKeyDown={({ key }: { key: string }) => key === 'Enter' && onEdit?.()}
        className={cx('text-input', { error: !!error })}
        name={name}
        type={type}
      />
      {editable && onEdit && <EditableFieldControls onEdit={onEdit} readOnly={readOnly} />}
      {error && <div className="error-label">{error}</div>}
    </label>
  );
};

type CommonFormFieldsProps = PropsWithChildren<{
  labelClassName?: string;
  className?: string;
  errors: Record<string, string>;
  readOnly?: Record<string, boolean> | boolean;
  required?: Record<string, boolean>;
  editable?: Record<string, boolean> | boolean;
  onEdit?: (fieldName: string) => void;
}>;

export const CommonFormFields: FC<CommonFormFieldsProps> = ({
  className,
  labelClassName,
  errors,
  readOnly,
  required,
  editable,
  children,
  onEdit,
}) => {
  const readOnlyConfig = typeof readOnly === 'boolean' ? undefined : readOnly;
  const readOnlyValue = typeof readOnly === 'boolean' ? readOnly : undefined;

  const editableConfig = typeof editable === 'boolean' ? undefined : editable;
  const editableValue = typeof editable === 'boolean' ? editable : undefined;

  return (
    <div className={cx(className, 'common-form-fields')}>
      <SimpleField
        className={labelClassName}
        name={CommonFormFieldName.FIRST_NAME}
        autoFocus
        readOnly={readOnlyConfig?.firstName ?? readOnlyValue}
        error={errors.firstName}
        required={required?.firstName}
        editable={editableConfig?.firstName ?? editableValue}
        onEdit={onEdit && (() => onEdit?.(CommonFormFieldName.FIRST_NAME))}
      />
      <SimpleField
        className={labelClassName}
        name={CommonFormFieldName.LAST_NAME}
        readOnly={readOnlyConfig?.lastName ?? readOnlyValue}
        error={errors.lastName}
        required={required?.lastName}
        editable={editableConfig?.lastName ?? editableValue}
        onEdit={onEdit && (() => onEdit?.(CommonFormFieldName.LAST_NAME))}
      />
      <SimpleField
        className={labelClassName}
        name={CommonFormFieldName.MIDDLE_NAME}
        readOnly={readOnlyConfig?.middleName ?? readOnlyValue}
        error={errors.middleName}
        required={required?.middleName}
        editable={editableConfig?.middleName ?? editableValue}
        onEdit={onEdit && (() => onEdit?.(CommonFormFieldName.MIDDLE_NAME))}
      />
      <PhoneField
        className={labelClassName}
        error={errors.phone}
        readOnly
        editable={false}
        required={required?.phone}
        onEdit={onEdit && (() => onEdit?.(CommonFormFieldName.PHONE))}
      />
      <SimpleField
        className={labelClassName}
        name={CommonFormFieldName.EMAIL}
        type="email"
        readOnly={readOnlyConfig?.email ?? readOnlyValue}
        error={errors.email}
        required={required?.email}
        editable={editableConfig?.email ?? editableValue}
        onEdit={onEdit && (() => onEdit?.(CommonFormFieldName.EMAIL))}
      />
      {children}
    </div>
  );
};

type PolicyAgreementProps = {
  className: string;
  textKey?: string;
};

export const PolicyAgreement: FC<PolicyAgreementProps> = ({ className, textKey = 'common.form.acceptPolicy' }) => {
  const { t } = useEnhancedTranslation({});
  return (
    <Field name={CommonFormFieldName.POLICY_AGREEMENT}>
      {({ field, form }: FieldProps) => (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <label
          onClick={() => form.setFieldValue(CommonFormFieldName.POLICY_AGREEMENT, !form.values.policyAgreement)}
          className={cx(className, 'checkbox-input-label')}
          htmlFor={CommonFormFieldName.POLICY_AGREEMENT}
        >
          <input {...field} required checked={field.value} className={cx('checkbox-input')} type="checkbox" />
          <p className="checkbox-input-text">
            <Trans
              t={t}
              i18nKey={textKey}
              components={[
                /* eslint-disable-next-line react/jsx-key */
                <Link
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: 'underline' }}
                  to={SiteMap.PRIVACY_POLICY}
                />,
                // eslint-disable-next-line react/jsx-key
                <Link
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: 'underline' }}
                  to={SiteMap.INFO_PROGRAM_TERMS}
                />,
              ]}
            />
          </p>
        </label>
      )}
    </Field>
  );
};
