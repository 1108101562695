import { tMap } from '@app/i18n';
import { LocalizedMap } from '@app/models';
import cx from 'classnames';
import { marked } from 'marked';
import { CSSProperties, FC, memo, useEffect, useState } from 'react';

import './markdown.scss';

type MarkdownProps = {
  className?: string;
  tag?: keyof JSX.IntrinsicElements;
  value?: string | LocalizedMap | null;
  src?: string | LocalizedMap;
  style?: CSSProperties;
};

const Markdown: FC<MarkdownProps> = ({ className, tag: Tag = 'p', value, src, style }) => {
  const [markdown, setMarkdown] = useState<string | LocalizedMap | null | undefined>(value);

  useEffect(() => {
    if (value) {
      setMarkdown(value);
    }
    if (!src) return;
    fetch(tMap(src))
      .then((val) => val.text())
      .then(setMarkdown);
  }, [src, value]);

  if (!markdown) return null;

  return (
    <Tag
      style={style}
      className={cx(className, 'markdown')}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: marked(tMap(markdown), { mangle: false, headerIds: false }) }}
    />
  );
};

const MemoizedMarkdown = memo(Markdown);

export { MemoizedMarkdown as Markdown };
