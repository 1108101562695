import { Footer, Header } from '@app/components';
import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import '@app/app.scss';

export const StubAuthLayout: FC = () => {
  return (
    <div className="app">
      <Header />
      <Suspense fallback={<div className="app__page-fallback" />}>
        <Outlet />
      </Suspense>
      <Footer />
    </div>
  );
};
