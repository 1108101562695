import { ReactComponent as LogoIcon } from '@app/assets/icons/logo-short.svg';

import './not-available.page.scss';
import { useAppSelector } from '@app/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { SiteMap } from '@app/constants';

const NotAvailableAuthPage = (): JSX.Element => {
  const stubAuthActive = useAppSelector((state) => state.stubReducer.stubAuthActive);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stubAuthActive) {
      navigate(SiteMap.HOME);
    }
  }, [stubAuthActive, navigate]);

  return (
    <main className="not-available">
      <div className="not-available__container">
        <LogoIcon className="not-available__icon" />
        <h3 className="not-available__title">Личный кабинет временно недоступен.</h3>
        <div className="not-available__text">
          Наши технические специалисты уже работают над решением проблемы. Пожалуйста, попробуйте зайти позже.
          <br />
          Если у вас есть вопросы, наши специалисты поддержки с радостью вам помогут. +7 495 73 777 73
        </div>
      </div>
    </main>
  );
};

export default NotAvailableAuthPage;
