import { useExternalControllerFindOneQuery } from '@app/api/external-api';
import { toCustomer } from '@app/api/mappers';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
// import { getPhone } from '../auth';
import { useAppSelector } from '@app/hooks';
import { Customer } from './account.model';

export const useCustomerQuery = (): ReturnType<typeof useExternalControllerFindOneQuery> & {
  data?: Customer;
} => {
  const { phone } = useAppSelector((state) => state.authReducer.access);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const resp = useExternalControllerFindOneQuery({ phone: phone || '' });

  const data = useMemo<Customer | undefined>(() => {
    return isEmpty(resp.data) ? undefined : toCustomer(resp.data);
  }, [resp.data]);
  return { ...resp, data };
};
