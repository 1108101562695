// Компонент отображает заглушку вместо компонента App если на сервере ведутся работы
import { FC, lazy, Suspense, useEffect } from 'react';
import { useAppDispatch } from '@app/hooks';
import { setStubActive, setAuthStubActive } from '@app/store/reducers/stubSlice';
import './app.scss';
import { useAppSettingsControllerGetQuery } from './api/appSettings/appSettingsApi';
import { App } from './app';

const NotAvailablePage = lazy(() => import('@app/pages/not-available/NotAvailablePage'));

export const AppWithStubb: FC = () => {
  const dispatch = useAppDispatch();
  // Используем RTK Query хук для получения данных о состоянии заглушки
  const { data: settings, refetch } = useAppSettingsControllerGetQuery(undefined, {
    pollingInterval: 10000, // Периодическая проверка каждые 10 секунд
  });

  useEffect(() => {
    dispatch(setStubActive(typeof settings?.stubActive === 'boolean' ? settings?.stubActive : false));
    dispatch(setAuthStubActive(typeof settings?.stubAuthActive === 'boolean' ? settings.stubAuthActive : false));
  }, [dispatch, settings]);

  // Получаем значение состояния заглушки, предположим, что оно приходит как свойство isMaintenance в ответе
  const isMaintenance = settings?.stubActive || false;

  useEffect(() => {
    refetch(); // При первой загрузке компонента делаем запрос
  }, [refetch]);

  if (isMaintenance) {
    return (
      <Suspense fallback={<div className="app__page-fallback" />}>
        <NotAvailablePage />
      </Suspense>
    );
  }

  return <App />;
};
