import { CardLink, CardLinkProps } from '@app/models';
import { FC } from 'react';

export function resolveCard<T extends CardLink>(
  cardModel: T,
  Card?: FC<CardLinkProps<T>>,
  CardFn?: (card: T) => FC<CardLinkProps<T>> | undefined,
): FC<CardLinkProps<T>> | null {
  const CardFC = CardFn?.(cardModel) ?? Card;
  if (!CardFC) {
    console.error('Provide valid Card or CardFN prop');
    return null;
  }
  return CardFC;
}
