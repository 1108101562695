import { MapCornerType } from '@app/models';

export type TImage =
  | 'logo'
  | 'coverMain'
  | 'coverBrand'
  | 'banner'
  | 'gallery'
  | 'coverMainHorizontal'
  | 'coverMainVertical'
  | 'catalog'
  | 'cover'
  | 'coverMin'
  | 'photo';

export type TFontColor = 'black' | 'white';
export type TRegistrationType = 'free' | 'registration' | 'vip';
export type TPublicationType = 'news' | 'project' | 'event';
export type TStatus = 'active' | 'disabled';
export type TShowButton = 'active' | 'disabled';
export type TEventType = 'event' | 'blog' | 'food' | 'service';
export type TTagType = 'event' | 'blog' | 'food' | 'service' | 'brand';
export type TEventBlockType = 'title' | 'text' | 'banner' | 'photo' | 'gallery';
export type TMainPageBlockType = 'banner' | 'video' | 'gradient';

export type TCornerType = `${MapCornerType}`;

export type SocialNetworksDto = {
  telegram?: string;
  vkontakte?: string;
  youtube?: string;
  ok?: string;
};

export type ImageResponseDto = {
  url: string;
  type: TImage;
  createAt?: string;
  sortOrder: number;
  _id: string;
};

export type LocaleDto = {
  ru: string;
  en: string;
};

export type PromoLevelDto = {
  level: string;
};

export type BrandsDiscountDto = {
  promoId: string;
  name: LocaleDto;
  images: ImageResponseDto[];
  floor: number;
  discountLevel: DiscountLevelDto[];
};

export type DiscountLevelDto = {
  level: string;
  discount: number;
  minSum: number;
};

export type ExternaBrandsDiscountDto = {
  promoID: string;
  discountLevel: DiscountLevelDto[];
};

export type TagsResponseDto = {
  _id: string;
  name: LocaleDto;
  count: never;
  sortOrder: number;
};

export type MappedEventInformationDto = {
  name: LocaleDto;
  startDate?: string;
  endDate?: string;
  sortOrder?: number;
  tags: any[];
  type: TEventType;
  images: ImageResponseDto[];
  shortId: string;
  seoName: string;
  cornerId?: string;
  descriptionBlockOne: LocaleDto;
  registrationType?: TRegistrationType;
  publicationType?: TPublicationType;
  createAt?: string;
  floor: number;
  fontColor: TFontColor;
  showBannerOnMain: never;
};

export type BrandResponseDto = {
  name: LocaleDto;
  descriptionBlockOne: LocaleDto;
  descriptionBlockTwo: LocaleDto;
  descriptionBlockThree: LocaleDto;
  socialNetworks?: SocialNetworksDto;
  siteUrl: string;
  video?: string;
  status?: TStatus;
  createAt?: string;
  updateAt?: string;
  tags: TagsResponseDto[];
  sortOrder: number;
  events?: MappedEventInformationDto[];
  blogs?: MappedEventInformationDto[];
  foods?: MappedEventInformationDto[];
  services?: MappedEventInformationDto[];
  gallery: ImageResponseDto[];
  images: ImageResponseDto[];
  floor: number;
  cornerId?: string;
  _id: string;
  shortId: string;
  seoName: string;
};

export type EventsWithTotalResponseDto = {
  data: EventResponseDto[];
  totalCount: number;
};

export type EventBlockDto = {
  type: TEventBlockType;
  text: LocaleDto;
  images: ImageResponseDto[];
};

export type EventResponseDto = {
  name: LocaleDto;
  descriptionBlockOne: LocaleDto;
  descriptionBlockTwo: LocaleDto;
  descriptionBlockThree?: LocaleDto;
  descriptionBlockFour?: LocaleDto;
  socialNetworks?: SocialNetworksDto;
  siteUrl?: string;
  video?: string;
  status?: TStatus;
  type: TEventType;
  createAt?: string;
  updateAt?: string;
  tags: any[];
  sortOrder?: number;
  startDate?: string;
  endDate?: string;
  events?: MappedEventInformationDto[];
  blogs?: MappedEventInformationDto[];
  foods?: MappedEventInformationDto[];
  services?: MappedEventInformationDto[];
  brands?: MappedEventInformationDto[];
  blocks: EventBlockDto[];
  gallery: ImageResponseDto[];
  images: ImageResponseDto[];
  fontColor: TFontColor;
  registrationType?: TRegistrationType;
  publicationType?: TPublicationType;
  floor: number;
  showBannerOnMain?: boolean;
  showFeedbackButton?: boolean;
  cornerId?: string;
  discountLevel?: string;
  pdfFile?: ImageResponseDto;
  _id: string;
  shortId: string;
  seoName: string;
  startTime: string;
  endTime: string;
  timeShow: boolean;
};

export type MainPageDto = {
  fontColor: TFontColor;
  blockType: TMainPageBlockType;
  showButton: TShowButton;
  buttonLink: string;
  buttonText: LocaleDto;
  text: LocaleDto;
  textVisible: boolean;
  mediaLink:
    | {
        _id: string;
        url: string;
        type: 'banner';
        createAt: Date;
      }
    | string;
  sortOrder?: number;
};

export type BrandShortDescriptionsMain = {
  name: LocaleDto;
  shortDescription: LocaleDto;
};
export type BrandsShortDescriptionsMain = {
  floor: number;
  brands: BrandShortDescriptionsMain[];
};

export type EventMain = {
  name: LocaleDto;
  type: TEventType;
  sortOrder?: number;
  tags: TagsResponseDto[];
  startDate?: string;
  endDate?: string;
  images: ImageResponseDto[];
  fontColor: TFontColor;
  floor: number;
  shortId: string;
  seoName: string;
  cornerId?: string;
  descriptionBlockOne: LocaleDto;
  showBannerOnMain?: boolean;
  descriptionBlockThree?: LocaleDto;
  descriptionBlockFour?: LocaleDto;
  registrationType?: TRegistrationType;
  publicationType?: TPublicationType;
  createAt?: string;
  discountLevel: never;
};

export type GiftCardBlock = {
  giftCard?: ImageResponseDto;
  giftCardCover?: ImageResponseDto;
};

export type PageResponseDto = {
  slider?: MainPageDto[];
  shortDescriptions?: BrandsShortDescriptionsMain[];
  events?: EventMain[];
  foods?: EventMain[];
  blogs?: EventMain[];
  services?: EventMain[];
  brands?: EventMain[];
  giftCardBlock?: GiftCardBlock | null;
};

export interface IMap {
  _id: string;
  cornerId: string;
  cornerType: string;
  feature: object;
  floor: number;
}

export type SearchMapDto = {
  cornerId: string;
  cornerType?: string;
  floor: number;
  shortId?: string;
  name?: LocaleDto;
  logo?: string;
  seoName?: string;
};

export enum FeedbackTypeEnum {
  feedback = 'feedback',
  contact = 'contact',
  reservation = 'reservation',
  dressing = 'dressing',
}

export type TFeedbackType = `${FeedbackTypeEnum}`;

export interface FeedbackDto {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  createAt?: string;
  type?: TFeedbackType;
  brandId: string;
  text: string;
}

export interface FeedbackResponseDto extends FeedbackDto {
  _id: string;
}

export type BadRequestDto = {
  statusCode: number;
  message: object;
  error: string;
};

export interface IParkingValue {
  startTime: string;
  endTime: string;
  price: number;
}
export interface InformationDto {
  socialNetworks: {
    telegram: string;
    vkontakte: string;
    youtube: string;
  };
  contacts: {
    address: string;
    email: string;
    phone: string;
  };
  workingHours: {
    weekdays: {
      startTime: string;
      endTime: string;
    };
    weekend: {
      startTime: string;
      endTime: string;
    };
  };
  parking: Array<IParkingValue>;
}

export interface InformationResponseDto extends InformationDto {
  _id: string;
}
