import { CookiePopup, Footer, Header, ProgramBanner, ScrollUp, NoInternetPopup } from '@app/components';
import { SiteMap } from '@app/constants';
import { FC, Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import '@app/app.scss';

export const AppLayout: FC = () => {
  const disableScrollUpOn = useMemo(() => [{ from: SiteMap.MAP, to: SiteMap.MAP }], []);

  return (
    <div className="app">
      <Header />
      <Suspense fallback={<div className="app__page-fallback" />}>
        <Outlet />
      </Suspense>

      <ProgramBanner />
      <Footer />
      <ScrollUp disableScrollUpOn={disableScrollUpOn} />
      <CookiePopup />
      <NoInternetPopup />
    </div>
  );
};
