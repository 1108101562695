import { Html5Qrcode, Html5QrcodeScanType, Html5QrcodeSupportedFormats, setTranslations } from '@alvedder/html5-qrcode';
import { useExternalControllerBarcodeMutation } from '@app/api/external-api';
import { ReactComponent as BarcodeIcon } from '@app/assets/icons/barcode.svg';
import { Button } from '@app/components';
import { useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import { t as globalT } from 'i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ScannedProduct } from './scanned-product';

import './loyality-barcode-scanner.scss';

setTranslations(globalT<string, Parameters<typeof setTranslations>[0]>('html5QrCode', { returnObjects: true }));

type LoyalityBarcodeScannerProps = {
  className?: string;
  customerId: string;
};

/**
 * TODO hack scanner message translations
 */
export const LoyalityBarcodeScanner: FC<LoyalityBarcodeScannerProps> = ({ className, customerId }) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.loyality.scanBarcode' });
  const [html5QrCode, setHtmlQrCode] = useState<Html5Qrcode>();
  const [scanActive, setScanActive] = useState(false);
  const [scannedCode, setScannedCode] = useState<string>();

  const [getProduct, { data: product, isError, isLoading }] = useExternalControllerBarcodeMutation();

  const scan = useCallback(() => {
    setScanActive(true);
    const scannerConfig = {
      fps: 10,
      qrbox: { width: 300, height: 200 },
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      useBarCodeDetectorIfSupported: true,
      formatsToSupport: [Html5QrcodeSupportedFormats.EAN_13, Html5QrcodeSupportedFormats.CODE_128],
    };
    const _html5QrCode = new Html5Qrcode('barcode-scanner-placeholder');
    setHtmlQrCode(_html5QrCode);
    _html5QrCode.start(
      { facingMode: 'environment' },
      scannerConfig,
      (decodedText) => {
        setScannedCode(decodedText);
        _html5QrCode.stop();
        setScanActive(false);
      },
      undefined,
    );
  }, []);

  useEffect(() => {
    if (!scannedCode) return;
    getProduct({ barcodeRequestDto: { customerId, barcode: scannedCode } });
  }, [customerId, getProduct, scannedCode]);

  useEffect(
    function stopScannerOnUnmount() {
      return () => {
        html5QrCode?.stop().then(() => html5QrCode?.clear());
      };
    },
    [html5QrCode],
  );

  const titleKey = useMemo(() => {
    if (product) return 'result';
    if (scannedCode && isLoading) return 'loader';
    if (scannedCode && isError) return 'notFound';
    return 'scan';
  }, [isError, isLoading, product, scannedCode]);

  return (
    <section className={cx(className, 'loyality-barcode')}>
      <div className="loyality-barcode__inner">
        <h2 className="loyality-barcode__title">{t(titleKey)}</h2>
        {!scanActive && !scannedCode && (
          <button type="button" onClick={scan} className="loyality-barcode__scan-btn">
            <BarcodeIcon />
          </button>
        )}

        {!scanActive && scannedCode && product && <ScannedProduct className="loyality-barcode__product" {...product} />}

        <div id="barcode-scanner-placeholder" className="loyality-barcode__placeholder" />

        {scannedCode && (
          <Button
            invertColors
            className="loyality-barcode__btn"
            onClick={() => {
              setScannedCode(undefined);
              scan();
            }}
          >
            {t('scanAnotherOne')}
          </Button>
        )}

        {scanActive && (
          <Button
            invertColors
            className="loyality-barcode__btn"
            onClick={() => {
              // TODO pause instead of stopping? Then the scanner shoud be initialized once in useEffect
              html5QrCode?.stop();
              setScanActive(false);
            }}
          >
            {t('stopScanning')}
          </Button>
        )}

        {!scannedCode && <p className="loyality-barcode__description">{t('description')}</p>}
      </div>
    </section>
  );
};
