import { RefObject, useLayoutEffect, useState } from 'react';
import { useElementSize } from './element-size.hook';

const findItemsPerRow = (boxStyle: CSSStyleDeclaration, itemStyles: CSSStyleDeclaration[]): number => {
  const avgItemSpacing = itemStyles
    .map(
      (itemStyle) => parseFloat(itemStyle.width) + parseFloat(itemStyle.marginLeft) + parseFloat(itemStyle.marginRight),
    )
    .reduce((sum, width, _idx, array) => sum + width / array.length, 0);

  const boxCapacity = parseFloat(boxStyle.width) - parseFloat(boxStyle.paddingRight) - parseFloat(boxStyle.paddingLeft);
  const columnGap = boxStyle.columnGap === 'normal' ? 0 : parseFloat(boxStyle.columnGap);

  let itemsPerRow = Math.max(Math.floor(Math.floor(boxCapacity) / Math.floor(avgItemSpacing)), 1);
  let totalSpacing = avgItemSpacing * itemsPerRow + columnGap * (itemsPerRow - 1);
  while (itemsPerRow > 1 && boxCapacity < totalSpacing) {
    itemsPerRow -= 1;
    totalSpacing = avgItemSpacing * itemsPerRow + columnGap * (itemsPerRow - 1);
  }
  return itemsPerRow;
};

/**
 * Works with `display: block` and `display: flex` when `flex-direction: row | row-reverse` and `box-sizing: border-box`
 */
export const useNotEnoughItems = <T extends HTMLElement>(boxRef: RefObject<T>, itemSelector: string): number => {
  const [notEnoughNum, setNotEnoughNum] = useState(0);
  const [items, setItems] = useState<HTMLElement[]>([]);

  const size = useElementSize(boxRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const latestFlexItems = Array.from(boxRef.current?.querySelectorAll(itemSelector) ?? []) as HTMLElement[];
    if (latestFlexItems.length !== items.length || latestFlexItems.some((item, idx) => item !== items[idx])) {
      setItems(latestFlexItems);
    }
  });

  useLayoutEffect(() => {
    if (!boxRef.current) {
      return;
    }

    if (!items?.length) {
      setNotEnoughNum(0);
      return;
    }

    const boxStyle = window.getComputedStyle(boxRef.current);
    const itemStyles = items.map((item) => window.getComputedStyle(item));
    const numPerRow = findItemsPerRow(boxStyle, itemStyles);
    if (boxStyle.flexWrap === 'wrap' || boxStyle.display === 'grid') {
      setNotEnoughNum(numPerRow - (items.length % numPerRow || numPerRow));
    } else {
      setNotEnoughNum(Math.max(numPerRow - items.length, 0));
    }
  }, [items, boxRef, itemSelector, size]);

  return notEnoughNum;
};
