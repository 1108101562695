import { useEventsControllerFindAllQuery } from '@app/api/eventsApi/events-api';
import { EventsControllerFindAllApiArg } from '@app/api/eventsApi/types';
import { toServiceCard } from '@app/api/mappers';
import { TServiceCard } from '@app/models';
import { compareSortable } from '@app/utils';
import { useMemo } from 'react';

export const useServiceCardsQuery = (
  arg: Omit<EventsControllerFindAllApiArg, 'type'>,
  options?: Parameters<typeof useEventsControllerFindAllQuery>[1],
): ReturnType<typeof useEventsControllerFindAllQuery> & {
  data: TServiceCard[];
  totalCount: number;
} => {
  const arg0 = {
    ...arg,
    type: 'service',
    limit: 100,
  } as EventsControllerFindAllApiArg;
  const resp = useEventsControllerFindAllQuery(arg0, options);
  const data = useMemo<TServiceCard[]>(() => {
    return resp.data?.data?.map(toServiceCard).sort(compareSortable) ?? [];
  }, [resp.data]);
  return { ...resp, data, totalCount: resp.data?.totalCount ?? 0 };
};
