import { connectEmptyApi as api } from '../connect-empty-api';
import {
  EventsControllerFindAllApiArg,
  EventsControllerFindAllApiResponse,
  EventsControllerFindOneApiArg,
  EventsControllerFindOneApiResponse,
  PagesControllerEventsByPhoneApiArg,
  PagesControllerEventsByPhoneApiResponse,
} from './types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    eventsControllerFindAll: build.query<EventsControllerFindAllApiResponse, EventsControllerFindAllApiArg>({
      query: (queryArg) => ({
        url: '/api/v1/events',
        params: queryArg,
      }),
    }),
    eventsControllerFindOne: build.query<EventsControllerFindOneApiResponse, EventsControllerFindOneApiArg>({
      query: (queryArg) => ({ url: `/api/v1/events/${queryArg.shortId}` }),
    }),
    pagesControllerEventsByPhone: build.query<
      PagesControllerEventsByPhoneApiResponse,
      PagesControllerEventsByPhoneApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/pages/events/byPhone/${queryArg.phone}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };

export const {
  useEventsControllerFindAllQuery,
  useLazyEventsControllerFindAllQuery,
  useEventsControllerFindOneQuery,
  useLazyEventsControllerFindOneQuery,
  usePagesControllerEventsByPhoneQuery,
  useLazyPagesControllerEventsByPhoneQuery,
} = injectedRtkApi;
