import { SiteMap } from '@app/constants';
import { Service, TServiceCard } from '@app/models';
import { compareSortable } from '@app/utils';
import capitalize from 'lodash/capitalize';
import { refineCornerId, refineFloor } from './mappers.utils';
import { convertImage, toTag } from './media.mappers';
import { EventMain, EventResponseDto } from '../models';

export const toServiceCard = ({
  shortId,
  name,
  seoName,
  images,
  sortOrder,
  descriptionBlockThree,
  descriptionBlockFour,
  floor,
  cornerId,
  discountLevel,
}: EventResponseDto | EventMain): TServiceCard => {
  const image = convertImage(images?.find((img) => img.type === 'banner'));
  return {
    id: shortId,
    name,
    link: `${SiteMap.SERVICES}/${seoName}-${shortId}`,
    type: 'service',
    image,
    description: descriptionBlockThree,
    sortOrder,
    floor: refineFloor(floor),
    cornerId: refineCornerId(cornerId),
    discountLevels: discountLevel
      ?.split(',')
      .map((level) => capitalize(level.trim()))
      .filter(Boolean),
    loyalityDescription: descriptionBlockFour,
  };
};

export const toService = ({
  shortId,
  seoName,
  name,
  images,
  descriptionBlockOne,
  descriptionBlockTwo,
  tags,
  floor,
  cornerId,
}: EventResponseDto): Service => {
  const logo = convertImage(images?.find((img) => img.type === 'logo'));
  return {
    id: shortId,
    name,
    logo,
    link: `${SiteMap.SERVICES}/${seoName}-${shortId}`,
    images: images.map(convertImage) ?? [],
    banner: convertImage(images?.find((img) => img.type === 'banner')),
    descriptions: [descriptionBlockOne, descriptionBlockTwo],
    tags: tags?.map(toTag).sort(compareSortable) ?? [],
    floor: refineFloor(floor),
    cornerId: refineCornerId(cornerId),
  };
};
