import * as Yup from 'yup';
import { isValid, parse } from 'date-fns';
import { DATE_FORMAT, EMAIL_REGEXP, NAME_REGEXP, PHONE_REGEXP } from '../regexp';

interface SignUpFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  birthday: string;
}

export const getSignUpSchema = (t: (key: string) => string): Yup.ObjectSchema<SignUpFormValues> =>
  Yup.object().shape({
    firstName: Yup.string()
      .transform((value) => (value ? value.replace(/[.\s]/g, '') : ''))
      .matches(NAME_REGEXP, t('validation.firstName.invalid'))
      .required(t('validation.firstName.required')),

    lastName: Yup.string()
      .transform((value) => (value ? value.replace(/[.\s]/g, '') : ''))
      .matches(NAME_REGEXP, t('validation.lastName.invalid'))
      .required(t('validation.lastName.required')),

    phone: Yup.string().matches(PHONE_REGEXP, t('validation.phone.invalid')).required(t('validation.phone.required')),

    email: Yup.string().matches(EMAIL_REGEXP, t('validation.email.invalid')).required(t('validation.email.required')),

    birthday: Yup.string()
      .test('valid-date', t('validation.birthday.invalid'), (dateStr) => {
        if (!dateStr) return false;
        const date = parse(dateStr, DATE_FORMAT, new Date());
        return isValid(date);
      })
      .required(t('validation.birthday.required')),
  });
