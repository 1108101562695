import { Accordion } from '@app/components';
import { useEnhancedTranslation, useMedia } from '@app/hooks';
import cx from 'classnames';
import { FC, useMemo } from 'react';
import { LOYALITY_PROGRESS } from '../../account.constants';
import { CustomerCaste, LoyalityCardLevel, TLoyalityCard } from '../../account.model';
import { getCustomerCaste } from '../../account.utils';

import './loyality-privileges.scss';

type LoyalityPrivilegesProps = {
  activeCardLevel: LoyalityCardLevel;
  className?: string;
  contentClassName?: string;
  cards: TLoyalityCard[];
};

export const LoyalityPrivileges: FC<LoyalityPrivilegesProps> = ({
  activeCardLevel,
  className,
  contentClassName,
  cards,
}) => {
  const { isMobile } = useMedia();
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.loyality.privileges' });

  const privilegeNodes = useMemo(() => {
    switch (getCustomerCaste(activeCardLevel)) {
      case CustomerCaste.MERE_MORTAL: {
        return cards.map((card) => {
          const cardPrivileges = t<string, string[]>(card.level, {
            returnObjects: true,
          });
          if (!cardPrivileges?.length) return null;
          return (
            <ul
              key={card.level}
              className={cx('loyality-privileges__group', {
                'loyality-privileges__group--disabled':
                  LOYALITY_PROGRESS[activeCardLevel] < LOYALITY_PROGRESS[card.level],
              })}
            >
              {cardPrivileges.map((item, itemIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className="loyality-privileges__item" key={itemIdx}>
                  <div className="loyality-privileges__bullet" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          );
        });
      }
      case CustomerCaste.STAFF:
      case CustomerCaste.GOD: {
        return (
          <ul className={cx('loyality-privileges__group')}>
            {t<string, string[][]>(cards[0].level, {
              returnObjects: true,
            })
              .flat()
              .map((item, itemIdx) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className="loyality-privileges__item" key={itemIdx}>
                  <div className="loyality-privileges__bullet" />
                  <span>{item}</span>
                </li>
              ))}
          </ul>
        );
      }
      default:
        return null;
    }
  }, [cards, activeCardLevel, t]);

  const summary = useMemo(() => <h2 className="loyality-privileges__title">{t('title')}</h2>, [t]);

  const content = useMemo(
    () => <div className={cx(contentClassName, 'loyality-privileges__content')}>{privilegeNodes}</div>,
    [contentClassName, privilegeNodes],
  );

  const accordionItems = useMemo(
    () => [
      {
        summary,
        content,
      },
    ],
    [content, summary],
  );

  return (
    <section className={cx(className, 'loyality-privileges')}>
      {isMobile ? (
        <Accordion
          items={accordionItems}
          selectId={() => '0'}
          active={accordionItems[0]}
          renderSummary={(item) => item.summary}
          renderContent={(item) => item.content}
        />
      ) : (
        <>
          {summary}
          {content}
        </>
      )}
    </section>
  );
};
