import { ReactComponent as HintIcon } from '@app/assets/icons/hint.svg';
import { ReactComponent as LogoIcon } from '@app/assets/icons/logo-short.svg';
import cardBg from '@app/assets/images/loyality-card-bg.webp';
import { Button, ButtonStyle, formatPrice, InteractiveDiv, Price } from '@app/components';
import { DATE_FORMAT } from '@app/features/common-form-fields';
import { useEnhancedTranslation } from '@app/hooks';
import { format } from 'date-fns';
import { gsap } from 'gsap';
import JsBarcode from 'jsbarcode';
import isNumber from 'lodash/isNumber';
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { TLoyalityCard } from '../../account.model';

import 'react-tooltip/dist/react-tooltip.css';
import './loyality-card.scss';

type LoyalityCardProps = {
  card: TLoyalityCard;
  code?: string;
  spentSum: number;
  promoEndsAt?: Date;
  acquired: boolean;
};

export const LoyalityCard: FC<LoyalityCardProps> = ({ card, code, spentSum, promoEndsAt, acquired }) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.loyality' });
  const timelineRef = useRef<gsap.core.Timeline>();
  const frontRef = useRef<HTMLDivElement>(null);
  const backRef = useRef<HTMLDivElement>(null);
  const barcodeRef = useRef<HTMLImageElement>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    if (!code) return;

    JsBarcode(barcodeRef.current, code, {
      format: 'CODE128',
      background: 'transparent',
      width: 4,
      fontSize: 30,
      text: code.replace(/\D/g, ''),
    });
  }, [code]);

  useLayoutEffect(() => {
    if (!code) return undefined;

    timelineRef.current = gsap
      .timeline({ paused: true })
      .set(frontRef.current, { zIndex: 1 })
      .set(backRef.current, { zIndex: 0 })
      .to(frontRef.current, { rotationY: -180, duration: 1, ease: 'power1.inOut' })
      .to(backRef.current, { rotationY: 0, duration: 1, ease: 'power1.inOut' }, 0)
      .set(frontRef.current, { zIndex: 0 }, '<50%')
      .set(backRef.current, { zIndex: 1 }, 0);

    return () => {
      timelineRef.current?.revert();
      timelineRef.current?.kill();
    };
  }, [code]);

  return (
    <figure className="loyality-card">
      <InteractiveDiv
        onClick={() => timelineRef.current?.play()}
        ref={frontRef}
        className="loyality-card__front"
        style={{ backgroundImage: `url(${cardBg})` }}
      >
        <div className="loyality-card__title-group">
          <span className="loyality-card__label">{t('yourCard')}</span>
          <h3 className="loyality-card__title">{`${card.discount}%`}</h3>
          {promoEndsAt && !acquired && isNumber(card.minSum) && (
            <span className="loyality-card__promo">
              {t('promoEndsAt', { endDate: format(promoEndsAt, DATE_FORMAT) })}
              <HintIcon
                className="loyality-card__promo-hint"
                onClick={() => setTooltipVisible((currentlyVisible) => !currentlyVisible)}
              />
              {/* TODO create a new Hint component */}
              <Tooltip
                className="loyality-card__tooltip"
                isOpen={tooltipVisible}
                anchorSelect=".loyality-card__promo-hint"
                variant="light"
                place="right"
              >
                {t('promoDescription', { endDate: format(promoEndsAt, DATE_FORMAT), sum: formatPrice(card.minSum) })}
              </Tooltip>
            </span>
          )}
        </div>
        {!!code && (
          <Button type="button" className="loyality-card__turn-btn" styling={ButtonStyle.ARROW} size="small">
            <span>{t('showCardBack')}</span>
          </Button>
        )}
        {isNumber(card.minSum) && (
          <div className="loyality-card__details">
            <span className="loyality-card__label">
              {/* eslint-disable-next-line no-nested-ternary */}
              {t(acquired ? 'currentSum' : 'remainedSum')}
            </span>
            <Price className="loyality-card__price" value={acquired ? spentSum : card.minSum - spentSum} />
          </div>
        )}
      </InteractiveDiv>

      <InteractiveDiv
        onClick={() => timelineRef.current?.reverse()}
        ref={backRef}
        className="loyality-card__back"
        style={{ backgroundImage: `url(${cardBg})` }}
      >
        <LogoIcon className="loyality-card__logo" />
        <Button type="button" className="loyality-card__turn-btn" styling={ButtonStyle.ARROW} size="small">
          <span>{t('showCardFront')}</span>
        </Button>
        <img ref={barcodeRef} className="loyality-card__barcode" alt="" />
      </InteractiveDiv>
    </figure>
  );
};
