import { StrictMode } from 'react';
import { initLocalization } from '@app/i18n';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.scss';
import { AppWithStubb } from './AppWithStub';
import { setupStore } from './store';

initLocalization();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Provider store={setupStore()}>
      <AppWithStubb />
    </Provider>
  </StrictMode>,
);
