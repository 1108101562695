import { connectEmptyApi as api } from '../connect-empty-api';
import { InformationControllerFirstApiResponse } from './types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    informationControllerFirst: build.query<InformationControllerFirstApiResponse, void>({
      query: () => ({
        url: '/api/v1/informations',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as colorApi };

export const { useLazyInformationControllerFirstQuery } = injectedRtkApi;
