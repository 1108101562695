import { colorApi as api } from '../informationApi';

export const informationTaggedApi = api.enhanceEndpoints({
  addTagTypes: ['Information'],
  endpoints: {
    informationControllerFirst: {
      providesTags: ['Information'],
    },
  },
});
