import { useEffect, useReducer, useRef, useMemo, useLayoutEffect } from 'react';

type UseAuthCodeStatusProps = {
  attemptExpiresAt?: Date;
};

type UseAuthCodeStatusReturn = {
  secondsLeft: number;
  canResend: boolean;
  startTimer: () => void;
};

export const useAuthCodeStatus = ({ attemptExpiresAt }: UseAuthCodeStatusProps): UseAuthCodeStatusReturn => {
  const codeAwaitTimeout = useMemo(() => {
    if (attemptExpiresAt) {
      const now = new Date();
      const expiresAt = new Date(attemptExpiresAt);
      return Math.floor(Math.abs(expiresAt.getTime() - now.getTime()) / 1000);
    }
    return 0;
  }, [attemptExpiresAt]);

  const decrementTimer = (state: number, payload: { reset: boolean }): number => {
    if (state === 0 || payload.reset) return codeAwaitTimeout;
    return state - 1;
  };

  const intervalRef = useRef<number | undefined>();
  const [secondsLeft, tick] = useReducer(decrementTimer, codeAwaitTimeout);

  const startTimer = (): void => {
    if (intervalRef.current && intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
      tick({ reset: true });
    }
    intervalRef.current = window.setInterval(() => {
      tick({ reset: false });
    }, 1000);
  };

  useLayoutEffect(() => {
    if (secondsLeft === 0) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }, [secondsLeft]);

  useEffect(() => {
    if (codeAwaitTimeout > 0) {
      startTimer();
    }
  }, [attemptExpiresAt, codeAwaitTimeout]);

  return {
    secondsLeft,
    canResend: secondsLeft === 0,
    startTimer,
  };
};
