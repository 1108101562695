import { connectEmptyApi as api } from '../connect-empty-api';
import {
  PagesControllerEventsByPhoneApiArg,
  PagesControllerEventsByPhoneApiResponse,
  PagesControllerGetAllBrandsDiscountApiArg,
  PagesControllerGetAllBrandsDiscountApiResponse,
  PagesControllerMainPageApiArg,
  PagesControllerMainPageApiResponse,
  PagesControllerMapPageApiArg,
  PagesControllerMapPageApiResponse,
  PagesControllerSearchMainApiArg,
  PagesControllerSearchMainApiResponse,
  PagesControllerSearchMapApiArg,
  PagesControllerSearchMapApiResponse,
} from './types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    pagesControllerMainPage: build.query<PagesControllerMainPageApiResponse, PagesControllerMainPageApiArg>({
      query: () => ({ url: '/api/v1/pages/main' }),
    }),
    pagesControllerMapPage: build.query<PagesControllerMapPageApiResponse, PagesControllerMapPageApiArg>({
      query: (queryArg) => ({
        url: '/api/v1/pages/map',
        params: queryArg,
      }),
    }),
    pagesControllerSearchMain: build.query<PagesControllerSearchMainApiResponse, PagesControllerSearchMainApiArg>({
      query: (queryArg) => ({ url: '/api/v1/pages/search/main', params: queryArg }),
    }),
    pagesControllerSearchMap: build.query<PagesControllerSearchMapApiResponse, PagesControllerSearchMapApiArg>({
      query: (queryArg) => ({
        url: '/api/v1/pages/search/map',
        params: { q: queryArg.q, type: queryArg.type, floor: queryArg.floor },
      }),
    }),
    pagesControllerEventsByPhone: build.query<
      PagesControllerEventsByPhoneApiResponse,
      PagesControllerEventsByPhoneApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/pages/events/byPhone/${queryArg.phone}` }),
    }),
    pagesControllerGetAllBrandsDiscount: build.mutation<
      PagesControllerGetAllBrandsDiscountApiResponse,
      PagesControllerGetAllBrandsDiscountApiArg
    >({
      query: (queryArg) => ({ url: '/api/v1/pages/brandsDiscount', method: 'POST', body: queryArg.promoLevelDto }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };

export const {
  usePagesControllerMainPageQuery,
  useLazyPagesControllerMainPageQuery,
  usePagesControllerMapPageQuery,
  useLazyPagesControllerMapPageQuery,
  usePagesControllerSearchMainQuery,
  useLazyPagesControllerSearchMainQuery,
  usePagesControllerSearchMapQuery,
  useLazyPagesControllerSearchMapQuery,
  usePagesControllerEventsByPhoneQuery,
  useLazyPagesControllerEventsByPhoneQuery,
  usePagesControllerGetAllBrandsDiscountMutation,
} = injectedRtkApi;
