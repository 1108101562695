import { useEnhancedTranslation } from '@app/hooks';
import { tMap } from '@app/i18n';
import { CardLinkProps, TEventCard } from '@app/models';
import { Link } from 'react-router-dom';
import colors from '@app/styles/colors.scss';
import cx from 'classnames';
import { format, isSameMonth } from 'date-fns';
import { FC, useMemo } from 'react';

import './event-card.scss';

type EventCardProps = CardLinkProps<TEventCard>;

const formatPeriod = (period: TEventCard['period']): string => {
  if (!period) {
    return '';
  }
  const startDateFormat = isSameMonth(period.start, period.end) ? 'd' : 'd MMM';
  return `${format(period.start, startDateFormat)}—${format(period.end, 'd MMM')}`;
};

export const EventCard: FC<EventCardProps> = ({
  className,
  period,
  link,
  image,
  backgroundColor,
  fontColor: textColor,
  name,
  registrationType: type,
}) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'events.registrationType' });
  const formattedPeriod = useMemo(() => formatPeriod(period), [period]);

  return (
    <figure className={cx(className, 'event-card')}>
      <Link to={link} className="event-card__link">
        <div
          className="event-card__bg event-card--hover"
          style={{
            backgroundImage: image ? `url(${image?.url})` : undefined,
            backgroundColor: !image ? backgroundColor || colors.mainBgColor : undefined,
            color: textColor || colors.mainInvertedTextColor,
          }}
        >
          <div
            className="event-card__content"
            style={{ backdropFilter: textColor !== 'black' ? 'brightness(80%)' : 'unset' }}
          >
            <p className="event-card__title">{tMap(name)}</p>
            <p className="event-card__period">{formattedPeriod}</p>
          </div>
        </div>
      </Link>
      <figcaption className="event-card__caption">
        <p className="event-card__caption-title">{tMap(name)}</p>
        <p className="event-card__details">
          <span>{formattedPeriod}</span>
          {type && <span>{t(type)}</span>}
        </p>
      </figcaption>
    </figure>
  );
};
