import { FC, ReactNode, ReactElement, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/redux';
import { SiteMap } from '@app/constants';

interface Props {
  children?: ReactNode;
}

export const ProtectedStubRoute: FC<Props> = ({ children }): ReactElement | null => {
  const stubAuthActive = useAppSelector((state) => state.stubReducer.stubAuthActive);

  useEffect(() => {
    console.log('stubAuthActive', stubAuthActive);
  }, [stubAuthActive]);

  if (stubAuthActive === undefined) {
    return <div style={{ height: '100vh' }} />;
  }

  if (stubAuthActive === true) {
    return <Navigate to={SiteMap.STUB_AUTH} replace />;
  }

  return children ? (children as ReactElement) : <Outlet />;
};
