import { SiteMap } from '@app/constants';
import { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { AppLayout, StubAuthLayout } from '@app/layouts';

import { CheckAvailableAuth } from '@app/features/auth/check-available-auth';
import { CheckAvailableSignUp } from '@app/features/auth/check-available-signup';
import { ProtectedRoute } from '@app/features/auth/protected-route';
import NotAvailableAuthPage from '@app/pages/not-available/NotAvailableAuthPage';
import { ProtectedStubRoute } from '@app/features/auth/protected-stub-route';
import { LkLayout } from '@app/layouts/LkLayout';
import {
  BrandsTab,
  FeedbackTab,
  LoyalityProgramTab,
  LoyalityTermsTab,
  PersonalInfoTab,
} from '@app/features/account/tabs';
import MyEventsTab from '@app/features/account/tabs/my-events/my-events.tab';
import { omitUrlPath } from '@app/utils';

const NewHomePage = lazy(() => import('@app/pages/new-home'));
const LoginPage = lazy(() => import('@app/features/auth/login.page'));
const SignUpPage = lazy(() => import('@app/features/auth/signup.page'));
// const AccountPage = lazy(() => import('@app/features/account'));
const EventsPage = lazy(() => import('@app/features/events/events.page'));
const EventPage = lazy(() => import('@app/features/events/event.page'));
const ServicesPage = lazy(() => import('@app/features/services/services.page'));
const ServicePage = lazy(() => import('@app/features/services/service.page'));
const BrandsPage = lazy(() => import('@app/features/brands/brands.page'));
const BrandPage = lazy(() => import('@app/features/brands/brand.page'));
const FoodPage = lazy(() => import('@app/features/food/food.page'));
const RestaurantPage = lazy(() => import('@app/features/food/restaurant.page'));
const StoriesPage = lazy(() => import('@app/features/stories/stories.page'));
const StoryPage = lazy(() => import('@app/features/stories/story.page'));
const LoyalityOverviewPage = lazy(() => import('@app/features/loyality'));
const AboutPage = lazy(() => import('@app/pages/about'));
const ContactsPage = lazy(() => import('@app/pages/contacts'));
const InfoPage = lazy(() => import('@app/pages/info'));
const SearchPage = lazy(() => import('@app/features/search'));
const NotFoundPage = lazy(() => import('@app/pages/not-found'));
const UnsortedPage = lazy(() => import('@app/pages/unsorted'));

const CertificatesPage = (): null => {
  window.location.href = 'https://gifts.tsvetnoy.com';
  return null;
};

export default createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <NewHomePage />,
      },
      {
        path: 'login',
        element: (
          <ProtectedStubRoute>
            <CheckAvailableAuth>
              <LoginPage />
            </CheckAvailableAuth>
          </ProtectedStubRoute>
        ),
      },
      {
        path: 'signup',
        element: (
          <ProtectedStubRoute>
            <CheckAvailableSignUp>
              <SignUpPage />
            </CheckAvailableSignUp>
          </ProtectedStubRoute>
        ),
      },
      {
        path: `${SiteMap.ACCOUNT}/*`,
        element: (
          <ProtectedStubRoute>
            <ProtectedRoute>
              <LkLayout />
            </ProtectedRoute>
          </ProtectedStubRoute>
        ),
        children: [
          {
            index: true,
            element: <LoyalityProgramTab />,
          },
          {
            path: omitUrlPath(SiteMap.LOYALITY_BRANDS),
            element: <BrandsTab />,
          },
          {
            path: omitUrlPath(SiteMap.PERSONAL_INFO),
            element: <PersonalInfoTab />,
          },
          {
            path: omitUrlPath(SiteMap.LK_PROGRAM_TERMS),
            element: <LoyalityTermsTab />,
          },
          {
            path: omitUrlPath(SiteMap.FEEDBACK),
            element: <FeedbackTab />,
          },
          {
            path: omitUrlPath(SiteMap.MY_EVENTS),
            element: <MyEventsTab />,
          },
        ],
      },
      {
        path: SiteMap.EVENTS,
        element: <EventsPage />,
      },
      {
        path: `${SiteMap.EVENTS}/:id`,
        element: <EventPage />,
      },
      {
        path: SiteMap.SERVICES,
        element: <ServicesPage />,
      },
      {
        path: `${SiteMap.SERVICES}/:id`,
        element: <ServicePage />,
      },
      {
        path: SiteMap.BRANDS,
        element: <BrandsPage />,
      },
      {
        path: `${SiteMap.BRANDS}/:id`,
        element: <BrandPage />,
      },
      {
        path: SiteMap.FOOD,
        element: <FoodPage />,
      },
      {
        path: `${SiteMap.FOOD}/:id`,
        element: <RestaurantPage />,
      },
      {
        path: SiteMap.CONTACTS,
        element: <ContactsPage />,
      },
      {
        path: SiteMap.STORIES,
        element: <StoriesPage />,
      },
      {
        path: `${SiteMap.STORIES}/:id`,
        element: <StoryPage />,
      },
      {
        path: SiteMap.INFO,
        element: <Navigate to={`${SiteMap.PRIVACY_POLICY}`} replace />,
      },
      {
        path: `${SiteMap.INFO}/*`,
        element: <InfoPage />,
      },
      {
        path: SiteMap.MAP,
        element: <Navigate to={`${SiteMap.BRANDS}`} replace />,
      },
      {
        path: `${SiteMap.MAP}/:floor/:cornerId?`,
        element: <Navigate to={`${SiteMap.BRANDS}`} replace />,
      },
      {
        path: SiteMap.LOYALITY_OVERVIEW,
        element: <LoyalityOverviewPage />,
      },
      {
        path: SiteMap.ABOUT,
        element: <AboutPage />,
      },
      {
        path: SiteMap.CERTIFICATES,
        element: <CertificatesPage />,
      },
      {
        path: SiteMap.SEARCH,
        element: <SearchPage />,
      },
      {
        path: SiteMap.UNSORTED,
        element: <UnsortedPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: SiteMap.STUB_AUTH,
    element: <StubAuthLayout />,
    children: [
      {
        index: true,
        element: <NotAvailableAuthPage />,
      },
    ],
  },
]);
