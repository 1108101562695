import { Outlet } from 'react-router-dom';
import { NavBar } from '@app/components';
import { SiteMap } from '@app/constants';
import { useEnhancedTranslation } from '@app/hooks';
import { LogoutButton } from '@app/components/logout-button';

import './lk-layout.scss';

const ACCOUNT_TABS = [
  {
    name: 'loyality.title',
    link: SiteMap.ACCOUNT,
  },
  {
    name: 'brands.title',
    link: SiteMap.LOYALITY_BRANDS,
  },
  {
    name: 'personalInfo.title',
    link: SiteMap.PERSONAL_INFO,
  },
  {
    name: 'loyalityTerms.title',
    link: SiteMap.LK_PROGRAM_TERMS,
  },
  {
    name: 'feedback.title',
    link: SiteMap.FEEDBACK,
  },
  {
    name: 'myEvents.title',
    link: SiteMap.MY_EVENTS,
  },
];

export const LkLayout: React.FC = (): JSX.Element => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account' });

  return (
    <div className="account-page">
      <div>
        <NavBar
          listClassName="account-page__tabs"
          itemClassName="account-page__tab"
          data={ACCOUNT_TABS.map((tab) => ({
            name: t(tab.name),
            link: tab.link,
          }))}
        />
        <LogoutButton type="account-page-tab" />
      </div>
      <Outlet />
    </div>
  );
};
