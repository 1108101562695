import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StubState {
  stubActive?: boolean;
  stubAuthActive?: boolean;
}

const initialState: StubState = {
  stubActive: undefined,
  stubAuthActive: undefined,
};

export const stubSlice = createSlice({
  name: 'stub',
  initialState,
  reducers: {
    setStubActive(state, action: PayloadAction<boolean>) {
      state.stubActive = action.payload;
    },
    setAuthStubActive(state, action: PayloadAction<boolean>) {
      state.stubAuthActive = action.payload;
    },
  },
});

export default stubSlice.reducer;

export const { setStubActive, setAuthStubActive } = stubSlice.actions;
