import { useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import { Field, FieldProps } from 'formik';
// import delay from 'lodash/delay';
import { FC } from 'react';
import * as Yup from 'yup';
import { TzFieldProps } from '../common-form-fields';

export const CODE_REGEXP = /^[0-9]{4}$/;

export const CODE_SCHEMA = Yup.object({
  code: Yup.string().length(4).matches(CODE_REGEXP).required('required'),
});

export const AuthCodeField: FC<TzFieldProps & { disabled: boolean }> = ({ className, readOnly, error, disabled }) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'auth' });
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={cx(className, 'field-label')} htmlFor="code">
      <Field name="code">
        {({ field, form }: FieldProps) => (
          <input
            {...field}
            disabled={disabled}
            readOnly={readOnly}
            onChange={(e) => {
              const newValue = e.target.value;
              if (/^\d{0,4}$/.test(newValue)) {
                form.setFieldValue('code', newValue);
              }

              // if (CODE_REGEXP.test(e.target.value)) {
              //   delay(() => form.submitForm(), 0);
              // }
            }}
            className={cx('text-input', { error: !!error })}
            name="code"
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            placeholder={t('enterCode') || ''}
          />
        )}
      </Field>
    </label>
  );
};
