import cx from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './button.scss';
import '@app/index.scss';

type LinkButtonProps = PropsWithChildren<
  LinkProps & {
    className?: string;
    to: string;
    invertColors?: boolean;
    externalLink?: boolean;
  }
>;

export const LinkButton: FC<LinkButtonProps> = ({ className, children, to, invertColors, externalLink, ...rest }) => {
  const classes = cx(className, 'button', 'button--outlined', {
    'button--invert-colors': invertColors,
  });

  if (externalLink) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={classes} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} className={classes} {...rest}>
      {children}
    </Link>
  );
};
