import { useEffect, useState } from 'react';
import ImagesLoadedEmitter from 'imagesloaded';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useImagesLoaded = (container: ImagesLoaded.ElementSelector | null, deps: any[]): boolean => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    setImagesLoaded(false);
  }, [container]);

  useEffect(() => {
    if (!container) return undefined;
    const onImagesLoaded = (): void => {
      setImagesLoaded(true);
    };
    const emitter = ImagesLoadedEmitter(container);
    emitter.on('always', onImagesLoaded);
    return () => emitter.off('always', onImagesLoaded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, ...deps]);

  return imagesLoaded;
};
