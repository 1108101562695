import { FC } from 'react';
import { NumericFormat, numericFormatter } from 'react-number-format';

type PriceProps = {
  className?: string;
  value: string | number;
  withCurrency?: boolean;
};

const PRICE_FORMAT_CONFIG = {
  decimalScale: 2,
  decimalSeparator: ',',
  thousandSeparator: ' ',
  allowNegative: false,
};

export const formatPrice = (value: string | number): string => {
  return numericFormatter(String(value), PRICE_FORMAT_CONFIG);
};

export const Price: FC<PriceProps> = ({ className, value, withCurrency }) => {
  return (
    <NumericFormat
      className={className}
      value={value}
      {...PRICE_FORMAT_CONFIG}
      suffix={withCurrency ? ' Р' : undefined}
      displayType="text"
    />
  );
};
