import { SiteMap } from '@app/constants';
import { LoyalityProgressBar } from '@app/features/loyality';
import { useEnhancedTranslation, useMedia } from '@app/hooks';
import cx from 'classnames';
import isFinite from 'lodash/isFinite';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LOYALITY_PROGRESS } from '../../account.constants';
import { useCustomerQuery } from '../../customer-query.hook';
import { LoyalityBarcodeScanner } from './loyality-barcode-scanner';
import { LoyalityCard } from './loyality-card';
import { LoyalityPrivileges } from './loyality-privileges';
import { LoyalityServices } from './loyality-services';
import { Wallets } from './wallets';

import './loyality.tab.scss';

export const LoyalityProgramTab: FC = () => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.loyality' });
  const { isMobile } = useMedia();

  const { data: customer } = useCustomerQuery();

  if (!customer) {
    return <main className="loyality-tab" />;
  }

  return (
    <main className="loyality-tab">
      <div className="loyality-tab__title-group">
        <h1 className="loyality-tab__title">{t('title')}</h1>
        <p className="loyality-tab__description">
          <Trans
            t={t}
            i18nKey="description"
            // eslint-disable-next-line react/jsx-key
            components={[<Link to={SiteMap.CONTACTS} />]}
          />
        </p>
      </div>

      <Swiper
        className="loyality-tab__swiper"
        slidesPerView="auto"
        grabCursor
        preventClicks={false}
        initialSlide={customer.cards.findIndex((card) => card.level === customer.cardLevel)}
        spaceBetween={isMobile ? 20 : 30}
      >
        {customer.cards.map((card) => (
          <SwiperSlide
            key={card.level}
            className={cx('loyality-tab__card', {
              'loyality-tab__card--disabled': LOYALITY_PROGRESS[customer.cardLevel] !== LOYALITY_PROGRESS[card.level],
            })}
          >
            <LoyalityCard
              card={card}
              code={card.level === customer.cardLevel ? customer.renderedCardId : undefined}
              spentSum={customer.spentSum}
              acquired={LOYALITY_PROGRESS[customer.cardLevel] >= LOYALITY_PROGRESS[card.level]}
              promoEndsAt={customer.promoEndsAt}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {isFinite(customer.spentSum) && (
        <LoyalityProgressBar
          className="loyality-tab__progress-bar"
          cards={customer.cards}
          spentSum={customer.spentSum}
          currentCardLevel={customer.cardLevel}
        />
      )}

      <Wallets className="loyality-tab__wallets" customer={customer} />

      {isFinite(customer.spentSum) && (
        <LoyalityPrivileges
          cards={customer.cards}
          className="loyality-tab__privileges"
          contentClassName="loyality-tab__privileges-content"
          activeCardLevel={customer.cardLevel}
        />
      )}
      {isMobile && (
        <>
          <section className="loyality-tab__discounts-hint">
            <p>
              <Trans
                t={t}
                i18nKey="discountsHint"
                components={[
                  /* eslint-disable-next-line react/jsx-key */
                  <Link to={SiteMap.LOYALITY_BRANDS} />,
                ]}
              />
            </p>
          </section>
          <LoyalityBarcodeScanner customerId={customer.customerId} className="loyality-tab__scan-barcode" />
        </>
      )}

      <LoyalityServices className="loyality-tab__services" discountLevel={customer.cardLevel} />
    </main>
  );
};

export default LoyalityProgramTab;
