import { useSendSmsCodeMutation } from '@app/api/auth-api';
import { useExternalControllerUpdateOneMutation } from '@app/api/external-api';
import { Button, ButtonStyle, Popover } from '@app/components';
import { cleanPhone, PhoneConfirmationForm, saveToken } from '@app/features/auth';
import { AuthCodeStatus } from '@app/features/auth/auth.model';
import { getSignUpSchema } from '@app/formValidation';
import { parseDateWithoutTimezone, toDDMMYYYY } from '@app/utils';
import {
  BirthdayField,
  CommonFormFieldName,
  CommonFormFields,
  DATE_FORMAT,
  formatPhone,
} from '@app/features/common-form-fields';
import { useEnhancedTranslation } from '@app/hooks';
import { Form, Formik } from 'formik';
import pick from 'lodash/pick';
import { FC, useMemo, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutButton } from '@app/components/logout-button';
import { useCustomerQuery } from '../../customer-query.hook';
import './personal-info.tab.scss';

const toggleReadOnlyFieldReducer = (state: Record<string, boolean>, fieldName: string): Record<string, boolean> => {
  return {
    ...state,
    [fieldName]: !state[fieldName],
  };
};

export const PersonalInfoTab: FC = () => {
  const { t, tRoot } = useEnhancedTranslation({ keyPrefix: 'account.personalInfo' });
  const navigate = useNavigate();
  const [readOnlyFields, toggleReadOnly] = useReducer(toggleReadOnlyFieldReducer, {
    [CommonFormFieldName.FIRST_NAME]: true,
    [CommonFormFieldName.LAST_NAME]: true,
    [CommonFormFieldName.MIDDLE_NAME]: true,
    [CommonFormFieldName.EMAIL]: true,
    [CommonFormFieldName.PHONE]: true,
    [CommonFormFieldName.BIRTHDAY]: true,
  });
  const [phoneConfirmationActive, setPhoneConfirmationActive] = useState(false);
  const [confirmPhone, setConfirmPhone] = useState<(confirmed: boolean) => void>();
  const [newPhone, setNewPhone] = useState<string>();
  const [editErrorMsg, setEditErrorMsg] = useState<string>();

  const [edit] = useExternalControllerUpdateOneMutation();
  const [login] = useSendSmsCodeMutation();
  const { data: customer } = useCustomerQuery();

  const personalInfo = useMemo(
    () => ({
      ...pick(customer, [
        CommonFormFieldName.FIRST_NAME,
        CommonFormFieldName.LAST_NAME,
        CommonFormFieldName.MIDDLE_NAME,
        CommonFormFieldName.EMAIL,
      ]),
      [CommonFormFieldName.PHONE]: customer?.phone ? formatPhone(customer.phone) : '',
      [CommonFormFieldName.BIRTHDAY]: customer?.birthday ? toDDMMYYYY(customer.birthday as unknown as string) : '',
    }),
    [customer],
  );

  if (!customer) {
    return <main className="personal-info-tab" />;
  }

  const onEdit = async (values: typeof personalInfo): Promise<void> => {
    const enteredPhone = cleanPhone(values.phone);
    if (enteredPhone !== customer.phone) {
      setPhoneConfirmationActive(true);
      setNewPhone(enteredPhone);
      login({ sendSmsCodeRequest: { phone: enteredPhone, captcha: '' } });
      const confirmed = await new Promise<boolean>((confirm) => {
        setConfirmPhone(() => confirm);
      });
      setConfirmPhone(undefined);
      setPhoneConfirmationActive(false);
      if (!confirmed) return;
    }
    const resp = await edit({
      customerUpdateDto: {
        ...values,
        phone: enteredPhone,
        birthday: values.birthday ? parseDateWithoutTimezone(values.birthday, DATE_FORMAT) : undefined,
      },
      customerId: customer.id,
    });
    if ('error' in resp) {
      setEditErrorMsg('editError');
      setTimeout(() => setEditErrorMsg(undefined), 10_000);
    } else {
      navigate(0);
    }
  };

  const onResend = async (): Promise<void> => {
    await login({ sendSmsCodeRequest: { phone: newPhone || '', captcha: '' } });
  };

  const onCodeSubmit = async (code: string): Promise<AuthCodeStatus> => {
    console.log('code', code);
    const resp = await login({
      sendSmsCodeRequest: {
        phone: newPhone || '',
        captcha: '',
      },
    });
    if ('error' in resp) {
      confirmPhone?.(false);
      return AuthCodeStatus.INVALID;
    }
    saveToken('');
    confirmPhone?.(true);
    return AuthCodeStatus.VALID;
  };

  return (
    <main className="personal-info-tab">
      <h1 className="personal-info-tab__title">{t('title')}</h1>
      <Formik initialValues={personalInfo} onSubmit={onEdit} validationSchema={getSignUpSchema(t)}>
        {({ errors, dirty, isValid, isSubmitting }) => (
          <Form>
            <CommonFormFields
              className="personal-info-tab__fields"
              labelClassName="personal-info-tab__label"
              errors={errors}
              readOnly={readOnlyFields}
              editable
              onEdit={toggleReadOnly}
              required={{
                [CommonFormFieldName.FIRST_NAME]: true,
                [CommonFormFieldName.LAST_NAME]: true,
                [CommonFormFieldName.MIDDLE_NAME]: true,
                [CommonFormFieldName.EMAIL]: true,
                [CommonFormFieldName.PHONE]: true,
              }}
            >
              <BirthdayField
                className="personal-info-tab__label"
                error={errors.birthday}
                editable
                readOnly={readOnlyFields[CommonFormFieldName.BIRTHDAY]}
                onEdit={() => toggleReadOnly(CommonFormFieldName.BIRTHDAY)}
              />
            </CommonFormFields>
            <div className="personal-info-tab__controls">
              <LogoutButton type="personal-info" />
              <Button
                styling={ButtonStyle.ARROW}
                disabled={!dirty || !isValid || isSubmitting}
                size={{ icon: 'small', text: 'medium' }}
                className="personal-info-tab__edit-btn"
                type="submit"
              >
                {tRoot('common.form.edit')}
              </Button>
            </div>
            {editErrorMsg && <p className="personal-info-tab__error">{t(editErrorMsg)}</p>}
          </Form>
        )}
      </Formik>

      <Popover open={phoneConfirmationActive} customCloseBtn>
        <PhoneConfirmationForm
          onResend={onResend}
          onSubmit={onCodeSubmit}
          status={AuthCodeStatus.PENDING}
          error="login_error"
        />
      </Popover>
    </main>
  );
};

export default PersonalInfoTab;
