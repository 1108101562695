import { BarcodeResponseDto } from '@app/api/external-api';
import { Price } from '@app/components';
import { useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import { FC } from 'react';

import './scanned-product.scss';

export const ScannedProduct: FC<BarcodeResponseDto & { className?: string }> = ({
  className,
  brandName,
  title,
  params,
  discountPrice,
  price,
}) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.loyality.scanBarcode.product' });
  return (
    <div className={cx(className, 'scanned-product')}>
      <span className="scanned-product__brand">{brandName}</span>
      <span className="scanned-product__title">{title}</span>
      {!!params && (
        <span className="scanned-product__params">
          {t('params.title')}
          <br />
          {t('params.size', { size: params.size })}
        </span>
      )}
      {price === discountPrice ? (
        <span className="scanned-product__price">
          {t('price')}
          <Price className="scanned-product__price-value" value={discountPrice} withCurrency />
        </span>
      ) : (
        <>
          <span className="scanned-product__original-price">
            {t('originalPrice')}
            <Price value={price} withCurrency />
          </span>
          <span className="scanned-product__price">
            {t('priceWithDiscount')}
            <Price className="scanned-product__price-value" value={discountPrice} withCurrency />
          </span>
        </>
      )}
    </div>
  );
};
