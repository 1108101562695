import { useFeedbacksControllerCreateMutation } from '@app/api/feedbackApi/feedback-api';
import { Button, Popover } from '@app/components';
import { cleanPhone } from '@app/features/auth';
import {
  CommonFormFieldName,
  CommonFormFields,
  COMMON_FORM_FIELDS_INITIAL_VALUES,
  COMMON_FORM_FIELDS_SCHEMA,
  PolicyAgreement,
  POLICY_AGREEMENT_SCHEMA,
} from '@app/features/common-form-fields';
import { useEnhancedTranslation } from '@app/hooks';
import { Field, Form, Formik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import './feedback-form.scss';
import { FeedbackDto, FeedbackTypeEnum, TFeedbackType } from '@app/api/models';

type BookRestaurantFormProps = {
  open: boolean;
  onClose: () => void;
  entityId?: string;
  type?: TFeedbackType;
};

export const FeedbackForm: FC<BookRestaurantFormProps> = ({
  open,
  onClose,
  entityId,
  type = FeedbackTypeEnum.feedback,
}) => {
  const { t, tRoot } = useEnhancedTranslation({ keyPrefix: 'feedback' });
  const [submitted, setSubmitted] = useState(false);

  const [createFeedback, { error: errorCreateFeedback, data: dataCreateFeedback }] =
    useFeedbacksControllerCreateMutation();

  const initialValues = useMemo(
    () => ({
      ...COMMON_FORM_FIELDS_INITIAL_VALUES,
      [CommonFormFieldName.POLICY_AGREEMENT]: false,
      comment: '',
    }),
    [],
  );

  const toFormatPayload = (values: typeof initialValues): FeedbackDto => {
    const { firstName, lastName, email, phone, comment } = values;
    return {
      firstName,
      lastName,
      email,
      phone: cleanPhone(phone),
      text: comment,
      type,
      brandId: entityId ?? '',
      createAt: new Date().toISOString(),
    };
  };

  const onSubmit = (values: typeof initialValues): void => {
    const payload = toFormatPayload(values);
    createFeedback({ feedbackDto: payload });
  };

  useEffect(() => {
    if (dataCreateFeedback) {
      setSubmitted(true);
    }
  }, [dataCreateFeedback]);

  return (
    <Popover className="feedback" open={open} onClose={onClose}>
      {submitted ? (
        <>
          <h2 className="feedback__title">{t(errorCreateFeedback ? 'error' : 'requestSent')}</h2>
          <Button className="feedback__submit-btn" onClick={onClose}>
            {tRoot('common.form.ok')}
          </Button>
        </>
      ) : (
        <>
          <h2 className="feedback__title">{t('writeToUs')}</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={COMMON_FORM_FIELDS_SCHEMA.concat(
              Yup.object({
                [CommonFormFieldName.EMAIL]: Yup.string().email().required(),
                comment: Yup.string(),
              }),
            ).concat(POLICY_AGREEMENT_SCHEMA)}
          >
            {({ dirty, errors, isValid }) => (
              <Form>
                <CommonFormFields
                  className="feedback__common-fields"
                  labelClassName="feedback__label"
                  errors={errors}
                />
                <Field
                  className="feedback__comment textarea"
                  name="comment"
                  as="textarea"
                  placeholder={tRoot('common.form.comment')}
                />
                <PolicyAgreement className="feedback__accept-policy" />
                <Button disabled={!isValid || !dirty} className="feedback__submit-btn" type="submit">
                  {tRoot('common.form.send')}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Popover>
  );
};
