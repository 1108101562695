import cx from 'classnames';
import { DetailedHTMLProps, FC, ForwardedRef, ImgHTMLAttributes, forwardRef, useState } from 'react';

import './img.scss';

const Img: FC<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = (
  { className, onLoad, ...rest },
  ref: ForwardedRef<HTMLImageElement>,
) => {
  const [loaded, setLoaded] = useState(false);
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      ref={ref}
      className={cx(className, 'img', {
        'img--loaded': loaded,
      })}
      onLoad={(event) => {
        setLoaded(true);
        onLoad?.(event);
      }}
      {...rest}
    />
  );
};

const ImgWithForwardedRef = forwardRef(Img);

export { ImgWithForwardedRef as Img };
