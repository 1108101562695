import { ReactComponent as CrossIcon } from '@app/assets/icons/cross.svg';
import { ReactComponent as SearchIcon } from '@app/assets/icons/search.svg';
import { useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import throttle from 'lodash/throttle';
import { ChangeEvent, FC, useCallback, useRef } from 'react';

import './brands-search.scss';

type BrandsSearchProps = {
  className?: string;
  onChange: (prompt: string) => void;
};

export const BrandsSearch: FC<BrandsSearchProps> = ({ className, onChange }) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.brands' });
  const searchInputRef = useRef<HTMLInputElement>(null);

  const onSearchChange = useCallback(
    throttle(({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(target.value.toLowerCase());
    }, 1500),
    [onChange],
  );

  return (
    <label htmlFor="search" className={cx(className, 'brands-search')}>
      <SearchIcon
        className={cx('brands-search__search-icon', {
          'brands-search__search-icon--active': !searchInputRef.current?.value,
        })}
      />
      <input
        ref={searchInputRef}
        className={cx('brands-search__input', 'text-input')}
        name="search"
        type="text"
        placeholder={t('search') || ''}
        onChange={onSearchChange}
      />
      <CrossIcon
        className={cx('brands-search__reset-icon', {
          'brands-search__reset-icon--active': !!searchInputRef.current?.value,
        })}
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          searchInputRef.current!.value = '';
          onChange('');
        }}
      />
    </label>
  );
};
