import { useLayoutEffect, useState } from 'react';
import { useFilterAnimation } from './filter-animation.hook';

export const useAnimatedItems = <T>(
  items: T[],
  updateOptions: Parameters<typeof useFilterAnimation>[1],
): T[] | undefined => {
  const [animatedCards, _setAnimatedCards] = useState<T[] | undefined>();

  const setViewCards = useFilterAnimation(_setAnimatedCards, {
    duration: 0.7,
    stagger: 0.1,
    ...updateOptions,
  });

  useLayoutEffect(() => setViewCards(items), [items, setViewCards]);

  return animatedCards;
};
