import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import difference from 'lodash/difference';
import {
  Dispatch,
  ReducerWithoutAction,
  RefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

gsap.registerPlugin(Flip);

export enum AnimationStatus {
  ENTERING = 'entering',
  ENTERED = 'entered',
  EXITING = 'exiting',
}

type FilterAnimationConfig = {
  duration?: number;
  stagger?: number;
  scope?: string | Element | RefObject<Element>;
  itemSelector: string;
  containerSelector: string;
  deps?: unknown[];
};

export const useFilterAnimation = <T>(
  setItems: Dispatch<ReducerWithoutAction<T[] | undefined>>,
  {
    itemSelector,
    containerSelector,
    duration = 1,
    stagger = 0,
    deps = [],
    scope = document.body,
  }: FilterAnimationConfig,
): ((items: T[]) => void) => {
  const [flipState, setFlipState] = useState<Flip.FlipState>();
  const [animationStatusMap, setAnimationStatusMap] = useState(new Map<T, AnimationStatus>());
  const ctx = useRef(gsap.context(() => {}));
  const targets = useMemo(() => [itemSelector, containerSelector].join(','), [containerSelector, itemSelector]);

  useLayoutEffect(() => {
    if (!flipState) return undefined;

    ctx.current.add(() => {
      const timeline = Flip.from(flipState, {
        targets: gsap.utils.selector(scope)(targets),
        duration,
        ease: 'power1.inOut',
        simple: true,
        nested: true,
        stagger,
        absolute: false,
        onEnter: (elements) => gsap.fromTo(elements, { opacity: 0, scale: 0.5 }, { opacity: 1, scale: 1, duration }),
        onLeave: (elements) => gsap.to(elements, { opacity: 0, scale: 0, duration }),
      });

      timeline.add(function removeItems() {
        setItems((currentItems) =>
          currentItems?.filter((item) => animationStatusMap.get(item) !== AnimationStatus.EXITING),
        );
      });
    });
    const currentCtx = ctx.current;
    return () => currentCtx.revert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, animationStatusMap, flipState, targets, duration, setItems, ...deps]);

  const setItemsWrapper = useCallback(
    (updatedItems: T[]) => {
      setItems((currentItems: T[] | undefined) => {
        const exitingItems = difference(currentItems, updatedItems);
        const enteringItems = currentItems ? difference(updatedItems, currentItems) : [];
        setAnimationStatusMap((map) => {
          currentItems?.forEach((event) => map.set(event, AnimationStatus.ENTERED));
          exitingItems.forEach((event) => map.set(event, AnimationStatus.EXITING));
          enteringItems.forEach((event) => map.set(event, AnimationStatus.ENTERING));
          return map;
        });
        setFlipState(Flip.getState(targets));
        return updatedItems;
      });
    },
    [setItems, targets],
  );

  return setItemsWrapper;
};
