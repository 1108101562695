import { api } from '../feedbackApi/feedback-api';

const taggedFeedbackApi = api.enhanceEndpoints({
  addTagTypes: ['MyEvents'],
  endpoints: {
    feedbacksControllerFindOne: {
      invalidatesTags: ['MyEvents'],
    },
    feedbacksControllerUnRegister: {
      invalidatesTags: ['MyEvents'],
    },
  },
});

export const { useFeedbacksControllerFindOneMutation, useFeedbacksControllerUnRegisterMutation } = taggedFeedbackApi;
