import bg from '@app/assets/images/loyality-brand-card-bg.webp';

import { Img } from '@app/components';
import { useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import { FC } from 'react';
import { LoyalityBrand } from '../../account.model';

import './loyality-brand-card.scss';

type LoyalityBrandCardProps = LoyalityBrand & {
  className?: string;
};

export const LoyalityBrandCard: FC<LoyalityBrandCardProps> = ({ className, name, logo, card }) => {
  const { tMap } = useEnhancedTranslation({ keyPrefix: 'account.brands' });
  return (
    <figure className={cx('loyality-brand', className)} style={{ backgroundImage: `url(${bg})` }}>
      <h3 className="loyality-brand__name">{tMap(name)}</h3>
      <span className="loyality-brand__hint" />
      {logo && (
        <picture className="loyality-brand__logo">
          <Img className="loyality-brand__logo-image" src={logo?.url} alt={tMap(name)} />
        </picture>
      )}
      <span className="loyality-brand__discount">{`${card.discount}%`}</span>
    </figure>
  );
};
