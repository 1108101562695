/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SiteMap } from '@app/constants';
import { EventRegistrationType, TEvent, TEventCard } from '@app/models';
import { compareSortable } from '@app/utils';
// eslint-disable-next-line import/no-cycle
import { toBrandCard } from './brand.mappers';
import { calcSortOrderByDate, refineFloor } from './mappers.utils';
import { convertImage, toTag } from './media.mappers';
import { EventMain, EventResponseDto, MappedEventInformationDto } from '../models';

const toPeriod = (
  startDate: string,
  endDate: string,
): {
  start: Date;
  end: Date;
} => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  if (end < start) {
    [start, end] = [end, start];
  }
  return {
    start,
    end,
  };
};

export const toEventCard = ({
  shortId,
  seoName,
  name,
  images,
  startDate,
  endDate,
  fontColor,
  registrationType,
}: EventResponseDto | EventMain | MappedEventInformationDto): TEventCard => {
  const image = convertImage(images?.find((img) => img.type === 'coverMain'));
  const period = toPeriod(startDate!, endDate!);
  return {
    id: shortId,
    name,
    link: `${SiteMap.EVENTS}/${seoName}-${shortId}`,
    type: 'event',
    sortOrder: calcSortOrderByDate(period.start),
    image,
    period,
    registrationType: registrationType as EventRegistrationType | undefined,
    fontColor,
  };
};

export const toEvent = ({
  shortId,
  seoName,
  name,
  images,
  gallery,
  descriptionBlockOne,
  descriptionBlockTwo,
  tags,
  socialNetworks,
  startDate,
  endDate,
  events,
  brands,
  fontColor,
  registrationType,
  floor,
  startTime,
  endTime,
  timeShow,
}: EventResponseDto): TEvent => {
  return {
    id: shortId,
    name,
    link: `${SiteMap.EVENTS}/${seoName}-${shortId}`,
    images: images.map(convertImage) ?? [],
    banner: convertImage(images?.find((img) => img.type === 'banner')),
    gallery: gallery.map(convertImage),
    descriptions: [descriptionBlockOne, descriptionBlockTwo],
    tags: tags.map(toTag).sort(compareSortable) ?? [],
    socialNetworks,
    period: toPeriod(startDate!, endDate!),
    fontColor,
    events: events?.map(toEventCard) ?? [],
    brands: brands?.map(toBrandCard) ?? [],
    registrationType: registrationType as EventRegistrationType,
    floor: refineFloor(floor),
    startTime,
    endTime,
    timeShow,
  };
};
