import { api } from '../pagesApi/pages-api';

const taggedPagesApi = api.enhanceEndpoints({
  addTagTypes: ['MyEvents'],
  endpoints: {
    pagesControllerEventsByPhone: {
      providesTags: ['MyEvents'],
    },
  },
});

export const { usePagesControllerEventsByPhoneQuery, useLazyPagesControllerEventsByPhoneQuery } = taggedPagesApi;
