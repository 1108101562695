import { OsmicardsLinkRequestDto, useExternalControllerOsmicardsLinkMutation } from '@app/api/external-api';
import { ReactComponent as AppleWalletLogo } from '@app/assets/icons/apple-wallet.svg';
import { ReactComponent as WalletUnionLogo } from '@app/assets/icons/wallet-union.svg';
import { useEnhancedTranslation } from '@app/hooks';
import cx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { ymGoal } from '@app/utils';
import { Customer } from '../../account.model';

import './wallets.scss';

type WalletsProps = {
  className?: string;
  customer: Customer;
};

export const Wallets: FC<WalletsProps> = ({ className, customer }) => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'account.loyality.wallets' });
  const [isError, setIsError] = useState(false);

  const [post] = useExternalControllerOsmicardsLinkMutation();

  const params = useMemo<OsmicardsLinkRequestDto>(() => {
    return {
      cardId: customer.cardId,
      customerId: customer.customerId,
      discount: customer.cards.find((card) => card.level === customer.cardLevel)?.discount || 0,
    };
  }, [customer]);

  const addToWallet = async (): Promise<void> => {
    setIsError(false);
    try {
      const windowRef = window.open();
      if (!windowRef) throw new Error('Popup window failed to open');
      const { link } = await post({ osmicardsLinkRequestDto: params }).unwrap();
      windowRef.location = link;
    } catch (error) {
      setIsError(true);
      setTimeout(() => setIsError(false), 10000);
    }
  };

  const addToWalletIos = (): void => {
    ymGoal('wallet_card_ios');
    addToWallet();
  };

  const addToWalletAndroid = (): void => {
    ymGoal('wallet_card_android');
    addToWallet();
  };

  return (
    <section className={cx(className, 'wallets')}>
      <p className={cx('wallets__description', { 'wallets__description--error': isError })}>
        {t(isError ? 'error' : 'description')}
      </p>
      <div className="wallets__buttons">
        <AppleWalletLogo className="wallets__button" onClick={addToWalletIos} />
        <WalletUnionLogo className="wallets__button" onClick={addToWalletAndroid} />
      </div>
    </section>
  );
};
