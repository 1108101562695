import { LoyalityCardLevel } from './account.model';

export const LOYALITY_PROGRESS: Record<Partial<LoyalityCardLevel>, number> = {
  [LoyalityCardLevel.CUST0]: -1,
  [LoyalityCardLevel.CUST5]: 0,
  [LoyalityCardLevel.CUST10]: 1,
  [LoyalityCardLevel.CUST15]: 2,
  [LoyalityCardLevel.STAFF5]: 2,
  [LoyalityCardLevel.STAFF10]: 2,
  [LoyalityCardLevel.STAFF20]: 2,
  [LoyalityCardLevel.STAFF30]: 2,
  [LoyalityCardLevel.STAFF_VIP]: 2,
  [LoyalityCardLevel.CUST20]: 2,
  [LoyalityCardLevel.CUST30]: 2,
};
