import { toLoyalityBrandCard } from '@app/api/mappers/account.mappers';
import { usePagesControllerGetAllBrandsDiscountMutation } from '@app/api/pagesApi/pages-api';
import uniqBy from 'lodash/uniqBy';
import { useMemo } from 'react';
import { LoyalityBrand, LoyalityCardLevel } from '../../account.model';

export const useLoyalityBrandCardsLazyQuery = (): [
  ReturnType<typeof usePagesControllerGetAllBrandsDiscountMutation>[0],
  ReturnType<typeof usePagesControllerGetAllBrandsDiscountMutation>[1] & {
    data: LoyalityBrand[];
  },
] => {
  const resp = usePagesControllerGetAllBrandsDiscountMutation();
  const data = useMemo<LoyalityBrand[]>(() => {
    return Array.isArray(resp[1]?.data)
      ? // TODO unique by id is WA because there are different promos with same id
        uniqBy(
          resp[1].data.map((brand) =>
            toLoyalityBrandCard(brand, resp[1].originalArgs?.promoLevelDto.level as LoyalityCardLevel),
          ),
          'id',
        )
      : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resp[1]?.data]);
  return [resp[0], { ...resp[1], data }];
};
